import BaseAPIClient from "./api";
import { AppConstants } from "../constants/AppConstants";
import axios from "axios";
import { couldStartTrivia } from "typescript";

const globalHeaders: any = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const API_BASE_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost";
const API = BaseAPIClient?.axiosInstance;

export const signUpAPICall = async (requestBody: any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.SIGNUP_URL}`;
  const response = await API.post(uri, requestBody, {
    headers,
  });
  return response;
};

export const signUpConfirmationAPICall = async (requestBody: any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.SIGNUP_CONFIRMATION_AWS_COGNITO}`;
  const response = await API.post(uri, requestBody, {
    headers,
  });
  return response;
};

export const verifyEmailBackend = async (email: string) => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.VERIFY_EMAIL_CHECK.replace(
    "<email>",
    email.replace("+", "%2b")
  )}`;
  const response = await API.post(
    uri,
    {},
    {
      headers,
    }
  );
  return response;
};

export const getUser = async (email: string = "") => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_USER}`;
  if (email) {
    uri += `?email=${email.replace("+", "%2b")}`;
  }
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const updateUser = async (formData: {}) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.UPDATE_USER}`;

  const response = await API.post(uri, formData, {
    headers,
  });
  return response;
};

export const getSubscriptions = async (email = "") => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}/subscription/`;

  if (email) {
    uri += `?email=${email.replace("+", "%2b")}`;
  }

  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const requestProductAccess = async (payload: {}) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}/subscription/request-product-access`;

  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const getRevokedApiKeys = async (email: string = "") => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}/subscription/revoked-keys`;

  if (email) {
    uri += `?email=${email.replace("+", "%2b")}`;
  }

  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const updateCompanyName = async (
  companyId: string,
  companyName: string,
  teamId: string,
  teamName: string
) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.UPDATE_COMPANY_TEAM_NAME}`;
  if (companyId && companyName) {
    uri += `?companyId=${companyId}&newCompanyName=${companyName}&teamId=${teamId}&newTeamName=${teamName}`;
  }
  const response = await API.put(uri, {
    headers,
  });
  return response;
};

export const updateCompanyTag = async (
  companyTag: string,
  userEmail : string
) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.UPDATE_COMPANY_TAG}`;
  if (companyTag && userEmail) {
    const encodedTags = encodeURIComponent(companyTag);
    const encodedEmail = encodeURIComponent(userEmail);
    uri += `?email=${encodedEmail}&tags=${encodedTags}`;
  }
  const response = await API.post(uri, {
    headers,
  });
  return response;
};

export const addNewUser = async (payload: {}) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.ADD_NEW_USER}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const signOutUser = async () => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.SIGN_OUT_USER}`;
  const response = await API.post(uri, {
    headers,
  });
  return response;
};

export const addNewUserToTeam = async (payload: {}) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.ADD_NEW_USER_TO_TEAM}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};
