import {
  Navigate,
  Route,
  Routes as RoutesReactRouterDom,
} from "react-router-dom";
import { RouteConstants } from "../constants/RouteConstants";
import { Suspense } from "react";
import { lazy } from "react";

import YourApiKey from "../components/UserMode/YourApiKey/YourApiKey";
import YourUsage from "../components/UserMode/YourUsage/YourUsage";
import HomeComponent from "../components/Shared/HomeComponent";
import YourUsageV2 from "../components/UserMode/YourUsage/YourUsageV2";
import PricingPage from "../components/UserMode/PricingPage";
import BatchUpload from "../components/UserMode/BatchUpload/BatchUpload";
import YourApiKeyNew from "../components/UserMode/YourApiKey/YourApiKeyNew";
import ApiKeyProductDetailsPage from "../components/UserMode/YuorApiKeyProductDetailsPage";
import TrestleAppBar from "../components/Shared/Appbar/Appbar";
import PublicPagesLayout from "../components/Shared/PublicLayout";
import LeadAssessmentForm from "../components/LeadAssessment";
import LeadAssessmentReportPage from "../components/LeadAssessment/LeadAssessmentReport";
import HubSpotHomePage from "../components/UserMode/HubSpot";
import HubSpotIntegrationPage from "../components/UserMode/HubSpot/HubSpotIntegartion";

export const UserRoute = () => {
  return (
    <Suspense fallback={<TrestleAppBar />}>
      <RoutesReactRouterDom>
        {/* version 2 */}
        <Route element={<TrestleAppBar />}>
          <Route
            path={RouteConstants.ROUTE_YOUR_USAGE}
            element={<YourUsageV2 />}
          />
          {/* <Route path={RouteConstants.ROUTE_YOUR_USAGE_V2} element={<YourUsageV2 />} /> */}
          <Route
            path={RouteConstants.ROUTE_YOUR_API_KEY}
            element={<YourApiKeyNew />}
          />
          <Route path={RouteConstants.ROUTE_HOME} element={<YourApiKeyNew />} />
          <Route
            path={RouteConstants.ROUTE_API_PRODUCTS_DETAILS}
            element={<ApiKeyProductDetailsPage />}
          />
          <Route
            path={RouteConstants.ROUTE_PRICING_PAGE}
            element={<PricingPage />}
          />
          <Route path={RouteConstants.BATCH_UPLOAD} element={<BatchUpload />} />
          <Route
            path={RouteConstants.ROUTE_HUB_SPOT}
            element={<HubSpotHomePage />}
          />
          <Route
            path={RouteConstants.ROUTE_HUB_SPOT_INTEGRATION}
            element={<HubSpotIntegrationPage />}
          />
          <Route
            path={"*"}
            element={<Navigate to={RouteConstants.ROUTE_HOME} />}
          />
        </Route>
        <Route element={<PublicPagesLayout />}>
          <Route
            path={RouteConstants.ROUTE_LEAD_ASSESSMENT_FORM}
            element={<LeadAssessmentForm />}
          />
          <Route
            path={RouteConstants.ROUTE_LEAD_ASSESSMENT_DETAILS}
            element={<LeadAssessmentReportPage />}
          />
        </Route>
      </RoutesReactRouterDom>
    </Suspense>
  );
};
