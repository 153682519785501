import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Text14pxMedium, WalletWarningText } from "../Shared/CommonComponents";
import styled from "@emotion/styled";
import TrestleButton from "../Shared/TrestleButton";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";
import { AppConstants } from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";
import {
  CreateManageSubscriptionSession,
  GetMaxioBillingPortalURL,
  GetMaxioCustomerID,
  GetWalletData,
  UpdatemonthlyTransactionLimit,
} from "../Shared/PaymentWallet/Services/PaymentWalletServices";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { useAppDispatch } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { HTTP_STATUS } from "../../http/constants/http.status";

interface BillingDetailsTabProps {
  setDialogOpen: any;
  setLoading: any;
  userEmail: any;
}

const BillingDetailsTab = ({
  setDialogOpen,
  setLoading,
  userEmail,
}: BillingDetailsTabProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [walletData, setWalletData] = useState<any>(null);
  const [newMonthlyLimit, setNewMonthlyLimit] = useState<string>("");
  const [isCustomerIdAvailable, setIsCustomerIdAvailable] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const fetchWalletData = async () => {
      const data = await GetWalletData(userEmail.replace("+", "%2b"));
      setWalletData(data?.data?.data);
      setIsLoading(false);
    };

    fetchWalletData();
  }, []);

  const fetchMaxioCustomerId = async () => {
    const data = await GetMaxioCustomerID(userEmail.replace("+", "%2b"));
    setIsCustomerIdAvailable(
      data?.data?.message === ConstantTexts.MAXIO_GET_CUSTOMER_ID_SUCCESS
    );
  };

  useEffect(() => {
    fetchMaxioCustomerId();
  }, []);

  const manageSubscriptionApiCall = async () => {
    setLoading(true);
    const response = await CreateManageSubscriptionSession(userEmail);
    const { status = 401, data = {} } = response;
    if (status === 200) {
      window.open(data?.data, "_self");
    }
  };

  const handleMonthlyLimitModifyClick = () => {
    setNewMonthlyLimit(walletData?.monthlyTransactionLimit?.toString() || "");
    setIsEditing(true);
  };

  const handleMonthlyLimitCancel = () => {
    setIsEditing(false);
    setNewMonthlyLimit("");
  };

  const handleMonthlyLimitSave = async () => {
    setIsSaving(true);
    try {
      const response = await UpdatemonthlyTransactionLimit(
        userEmail,
        newMonthlyLimit
      );
      if (response.status === 200) {
        setWalletData((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            monthlyTransactionLimit: parseInt(newMonthlyLimit),
          },
        }));
        setIsEditing(false);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: ConstantTexts.SPEND_LIMIT_UPDATED,
          })
        );
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.SPEND_LIMIT_UPDATE_ERROR,
          })
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: ConstantTexts.SPEND_LIMIT_UPDATE_ERROR,
        })
      );
    }
    setIsSaving(false);
  };

  const handleMaxioBillingPortalRedirect = async () => {
    try {
      const data = await GetMaxioBillingPortalURL(
        userEmail.replace("+", "%2b")
      );

      // Check if there was an error in the response
      if (data?.data?.code !== HTTP_STATUS.HTTP_OK) {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
          })
        );
        return;
      }

      // If no error, get the URL from the response and redirect
      const url = data?.data?.data?.url;

      if (url) {
        // Redirect to the URL in a new tab/window
        window.open(url, "_blank");
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
          })
        );
      }
    } catch (error) {
      // Handle any unexpected errors in the API call
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
        })
      );
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      {walletData?.walletDisplayType === "SELF_SERVE" ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Text14pxMedium>
                {`Wallet balance: ${
                  walletData.balance !== undefined &&
                  walletData.balance !== null
                    ? parseFloat(walletData.balance) < 0
                      ? `-$${Math.abs(parseFloat(walletData.balance)).toFixed(
                          2
                        )}`
                      : `$${parseFloat(walletData.balance).toFixed(2)}`
                    : "0.00"
                }`}
              </Text14pxMedium>
              <Text14pxMedium>
                {walletData.autoRechargeEnabled
                  ? "Automatic wallet refill enabled"
                  : "Automatic wallet refill disabled"}
              </Text14pxMedium>
              {walletData.autoRechargeEnabled && (
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "15px" }}
                >
                  <Text14pxMedium>
                    Threshold: ${walletData.balanceThreshold}
                  </Text14pxMedium>
                  <Text14pxMedium>
                    Refill balance to: ${walletData.targetBalance}
                  </Text14pxMedium>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <ActionButtonContainer
                u={"underline"}
                onClick={manageSubscriptionApiCall}
              >
                Stripe Billing Details
              </ActionButtonContainer>
            </Box>
          </PlanDetailsCardContainer>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {isEditing ? (
              <>
                <input
                  type="number"
                  value={newMonthlyLimit}
                  onChange={(e) => setNewMonthlyLimit(e.target.value)}
                  className="border border-gray-300 rounded px-2 py-1 w-[120px]"
                />
                <TrestleButton
                  onClick={handleMonthlyLimitSave}
                  label="Save"
                  type="primary"
                  className="!h-[40px] !w-[30px]"
                  disabled={isSaving}
                />
                <TrestleButton
                  onClick={handleMonthlyLimitCancel}
                  label="Cancel"
                  type="secondry"
                  className="!h-[40px] !w-[30px]"
                />
              </>
            ) : (
              <>
                <Text14pxMedium>
                  Monthly Spend Limit: $
                  {walletData?.monthlyTransactionLimit || 0}
                </Text14pxMedium>
                <TrestleButton
                  onClick={handleMonthlyLimitModifyClick}
                  label="Modify"
                  type="primary"
                  className="!h-[40px] !w-[30px] bg-[#E0EEF1] !text-[#0494B4]"
                />
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <WalletWarningText c={"#163545"}>Plan</WalletWarningText>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Text14pxMedium>
                {walletData?.walletDisplayType === "UNLIMITED"
                  ? "Enterprise"
                  : "Trial"}
              </Text14pxMedium>
            </Box>
          </PlanDetailsCardContainer>
        </Box>
      )}
      {/* Conditionally render Billing Portal URL button when isCustomerIdAvailable is true */}
      {isCustomerIdAvailable && (
        <TrestleButton
          onClick={handleMaxioBillingPortalRedirect} // Ensure this handler exists
          label="Billing Portal"
          type="primary"
          className="!h-[50px] !leading-[40px] !py-[20px] !pl-[20px] !pr-[20px] !w-fit !mt-[30px]"
        />
      )}
    </>
  );
};

export const PlanDetailsCardContainer = styled.div`
  width: 100%;
  height: flex;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
`;

export const SaveChangesBtnPopup = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  margin-left: -40px;
  width: 75%;
  box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
`;

export default BillingDetailsTab;
