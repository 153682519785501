export class MixPanelEvents {
  static readonly USER_LOGIN = "Visit Login Page";
  static readonly USER_SIGNUP = "Visit Sign Up Page";
  static readonly USER_SIGNUP_INVALID_EMAIL = "Invalid Email Address";

  static readonly USER_CLICKS_CONTINUE_TO_SIGNIN = "Click Continue to Sign In";
  static readonly USER_CLICKS_RESEND_VERIFICATION_EMAIL =
    "Click Resend Verification Email";
  static readonly USER_CLICKS_RETURN_TO_SIGNUP = "Click Return to Sign Up";

  static readonly SWITCH_USER_MODE = "UserMode Changed";
  static readonly CLICK_DOCUMENTATION = "Clicked Documentation";
  static readonly CLICK_NEED_HELP_ICON = "Clicked Need Help Icon";

  static readonly VISIT_YOURAPIKEY_PAGE = "Visit User Home Page - API keys";
  static readonly VISIT_USER_USAGE_PAGE = "Visit User - Usage Page";

  static readonly VISIT_ADMIN_PRODUCT_ACCESS = "Visit Admin - Product Access";
  static readonly VISIT_ADMIN_ALL_USERS = "Visit Admin - All Users";
  static readonly VISIT_ADMIN_USAGE_PAGE = "Visit Admin - Usage";

  static readonly INVITE_NEW_USER = "Add New User Popup Open";
  static readonly INVITE_NEW_TEAM_MEMBER = "Add New User to Team Popup Open";
  static readonly ADMIN_ENTERED_INVALID_EMAIL =
    "Admin Entered Invalid Email Address";
  static readonly USER_ENTERED_INVALID_EMAIL =
    "User Entered Invalid Email Address";
  static readonly INVITE_SENT_SUCCESSFULLY =
    "User Invitation Sent Successfully";
  static readonly TEAM_INVITE_SENT_SUCCESSFULLY =
    "User Invited To The Team Successfully";

  static readonly TEST_REQUEST = "Test Request";
  static readonly SUBMIT_TEST_REQUEST = "Submit Test Request";

  static readonly WALLET_DROPDOWN_CLICK = "Click Wallet dropdown button";
  static readonly WALLET_ADD_TO_WALLET_BUTTON = "Click on Add To Wallet button";
  static readonly WALLET_UPGRADE_PLAN_BUTTON = "Click on Upgrade Plan button";
  static readonly WALLET_CHECKOUT_BUTTON = "Click on Go To Checkout button";
  static readonly WALLET_CHECKOUT_SESSION = "User checkouts session";
  static readonly WALLET_CONTACT_SALES =
    "Click on Contact Sales To Discuss button";
  static readonly WALLET_BACK_BUTTON = "Click on Back button";
  static readonly WALLET_AUTOMATIC_REFILL = "Click on Automatic Refill Button";
  static readonly WALLET_PAYMENT_SUCCESS_CHECK = "User payment successful";
  static readonly WALLET_BOOK_MEETING = "Book a meeting";
  static readonly WALLET_TRIAL_UPGRADE_PLAN = "Click on Upgrade Plan in Trial";
  static readonly WALLET_UPGRADE_NOW_BANNER =
    "Click on Upgrade Now button in banner";
  static readonly WALLET_CHANGE_BUTTON =
    "Click on change button in wallet dropdown";
  static readonly WALLET_UPDATE_SELECTION_BUTTON =
    "Click on Update Selection button";
  static readonly WALLET_ERROR_FOR_UPDATE_WALLET_DATA_API =
    "Click on Update Selection Button to call API";
  static readonly WALLET_VALIDATION_ERRORS_SELF_SERVE_TAB =
    "User encounters a validation error in self-serve tab";
  static readonly WALLET_VALIDATION_ERRORS =
    "User encounters a validation error";
  static readonly PLAN_UPGRADE_SUCCESS =
    "User successfully upgrades plan to self-serve";


  //batch upload events
  static readonly VISIT_USER_BATCH_PAGE = "Visited the Batch page - User";
  static readonly VISIT_ADMIN_BATCH_PAGE = "Visited the Batch page - Admin";
  static readonly CLICKED_ON_BATCH_UPLOAD = "Clicked on Batch Upload button";
  static readonly CLICKED_SUBMITED_BATCH_UPLOAD = "Clicked on Batch Upload → Submit button";
  static readonly BATCH_UPLOAD_ERROR = "Got an error when doing a Batch Submit";
  
  static readonly DATA_ASSESSMENT_VISIT = "Visited Data Assessment"
  static readonly DATA_ASSESSMENT_REPORT_VISIT="Visited Data Assessment Report";

}
