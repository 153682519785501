import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { BrandingPage } from "../../Shared/BrandingPage";
import {
  MainHeaderText,
  BrandingPageChildContainer,
} from "../../Shared/CommonComponents";
import InputTextField from "../../Shared/InputTextField";
import Grid from "@mui/material/Grid2";
import TrestleButton from "../../Shared/TrestleButton";
import { BackwardArrow, MailIcon } from "../../../assets/svgs";
import { RouteConstants } from "../../../constants/RouteConstants";
import { validationEmail, validatePassword } from "../../../utils/validations";
import { AppConstants } from "../../../constants/AppConstants";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { setToast } from "../../../app/toastReducer";
import Auth from "@aws-amplify/auth";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token: any = useAppSelector((state: any) => state.authReducer.token);

  const [formData, setFormData] = useState<any>({
    email: "",
  });
  const [formError, setFormError] = useState<any>({
    email: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const OnDataChange = (event: any) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const disabledSubmit = () => {
    const { email = "" } = formData || {};
    return !email.trim();
  };

  const handleValidation = () => {
    const { email = "" } = formData || {};
    let isError: boolean = true;

    let tempError = {
      email: validationEmail(email),
    };

    if (tempError.email) {
      isError = false;
    }

    setFormError({ ...tempError });
    return isError;
  };

  const SignInCall = async () => {
    const isError: boolean = handleValidation();

    if (isError && !disabledSubmit()) {
      setIsLoading(true);

      try {
        const response = await Auth.forgotPassword(formData.email);
        sessionStorage.setItem("userEmail", formData.email);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: AppConstants.FORGOT_PASSWORD_MAIL_SENT,
          })
        );
        navigate(RouteConstants.ROUTE_CONFIRM_RESET_PASSWORD);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: error.message,
          })
        );
        console.log("error: ", error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trestle Developer Portal - Forgot Password</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DEV_PORTAL_URL}reset-password`}
        />
      </Helmet>
      <BrandingPage loader={isLoading}>
        <BrandingPageChildContainer>
          <MainHeaderText>{ConstantTexts.RESET_PASSWORD_HEADER}</MainHeaderText>
          <div className="mt-[20px] text-base font-normal text-[#163545]">
            {ConstantTexts.RESET_PASSWORD_INFO_TEXT}
          </div>

          <div
            className="mt-[50px] mb-[30px] sm:my-[60px]"
            style={{ marginBottom: "13px" }}
          >
            <Grid container spacing={4} className="!block sm:!flex">
              <Grid
                size={{ sm: 12 }}
                className="!block !pt-[0] sm:!flex sm:!pt-[25px]"
              >
                <InputTextField
                  name={"email"}
                  label={"Email Address"}
                  value={formData}
                  error={formError}
                  type={"text"}
                  onChange={OnDataChange}
                  required={true}
                  maxLength={AppConstants.MAX_EMAIL_LENGTH}
                />
              </Grid>
            </Grid>
          </div>

          <div className="w-[100%]">
            <TrestleButton
              onClick={SignInCall}
              label="Reset Password"
              disabled={disabledSubmit()}
              type="primary"
              icon={MailIcon}
              iconFirst={true}
            />
            <TrestleButton
              onClick={() => navigate(RouteConstants.ROUTE_LOGIN)}
              label="Back to Sign In"
              type="secondry"
              icon={BackwardArrow}
              iconFirst={true}
              className="mt-3"
            />
          </div>
        </BrandingPageChildContainer>
      </BrandingPage>
    </>
  );
};

export default ResetPassword;
