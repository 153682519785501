import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToast } from "../../app/toastReducer";
import TrestleTable, {
  TableHeaderProps,
} from "../Shared/TrestleTable/TrestleTable"; // assuming you have TrestleTable component
import { HTTP_STATUS } from "../../http/constants/http.status"; // HTTP status constants
import { getUserVerificationData } from "../../http/user-service"; // service to fetch verification data
import "./UserVerificationData.css";

interface VerificationDataProps {
  setLoading: Function;
  usersDetails: any; // current user details, which include the email
  isAdmin?: boolean;
}

function VerificationData({
  setLoading,
  usersDetails,
  isAdmin,
}: VerificationDataProps) {
  const dispatch = useDispatch();
  const [verificationData, setVerificationData] = useState<any[]>([]);

  useEffect(() => {
    // Fetch the verification data when the component mounts
    fetchVerificationData();
  }, []);

  const fetchVerificationData = async () => {
    setLoading(true);
    const email = usersDetails?.email; // Extract the email from usersDetails
    if (!email) {
      dispatch(
        setToast({
          open: true,
          severity: "error",
          msg: "User email is missing.",
        })
      );
      setLoading(false);
      return;
    }

    try {
      const response = await getUserVerificationData(email); // Pass email to the service
      if (response?.status === HTTP_STATUS.HTTP_OK) {
        const data = response.data || {}; // Ensure data is an object

        // List of required attributes with new names (matching the screenshot)
        const requiredAttributes = [
          { key: "phoneToNameMatch", label: "Phone to Name Match" },
          { key: "phoneLineType", label: "Phone Linetype" },
          { key: "clearbit", label: "Clearbit" },
          { key: "atDataEmailFirstSeen", label: "AtData Email First Seen" },
          { key: "domainCheck", label: "Domain Check" },
          { key: "ipAddressCheck", label: "IP Address Check" },
        ];

        // Filter the response data to include only the required attributes and show false if needed
        const filteredData = requiredAttributes.map((attribute) => ({
          attribute: attribute.label, // Using the custom label for each attribute
          data:
            data[attribute.key] === null || data[attribute.key] === undefined
              ? "-"
              : data[attribute.key] === false
              ? "False"
              : data[attribute.key] === true
              ? "True"
              : data[attribute.key], // Display 'False' if the value is false
        }));

        setVerificationData(filteredData); // Set filtered data as an array
      } else {
        dispatch(
          setToast({
            open: true,
            severity: "error",
            msg: response.data?.details || "Failed to fetch data.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          open: true,
          severity: "error",
          msg: "Error occurred while fetching data.",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "attribute",
      numeric: false,
      disablePadding: false,
      label: "Attribute",
      width: "50%", // Fixed width for the attribute column
      isCustomElements: false,
      customElements: () => {},
      sortable: false,
      headClassName: "custom-bold-header",
    },
    {
      id: "data",
      numeric: false,
      disablePadding: false,
      label: "Data",
      width: "50%", // Fixed width for the data column
      isCustomElements: false,
      customElements: () => {},
      sortable: false,
      headClassName: "custom-bold-header",
    },
  ];

  return (
    <div className="verification-data-container">
      <div className="table-container">
        <TrestleTable
          isPaginated={false}
          headCells={headCells}
          rows={verificationData} // Pass the filtered data as rows
        />
      </div>
    </div>
  );
}

export default VerificationData;
