import React from "react";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import {
  ComponentLabelHeader,
  Text14pxMedium,
  WalletWarningText,
} from "../Shared/CommonComponents";
import styled from "@emotion/styled";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import {
  CreateManageSubscriptionSession,
  GetMaxioBillingPortalURL,
  GetMaxioCustomerID,
} from "../Shared/PaymentWallet/Services/PaymentWalletServices";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { useAppDispatch } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { AppConstants } from "../../constants/AppConstants";

import TrestleButton from "../Shared/TrestleButton";
import { HttpStatusCode } from "axios";
import { HTTP_STATUS } from "../../http/constants/http.status";

interface BillingDetailsTabProps {
  setDialogOpen: any;
  setLoading: any;
}

const BillingDetailsTab = ({
  setDialogOpen,
  setLoading,
}: BillingDetailsTabProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const walletData = useAppSelector((state) => state.walletReducer.walletData);
  const [isCustomerIdAvailable, setIsCustomerIdAvailable] =
    useState<boolean>(false);

  const trialUser =
    walletData?.walletDisplayType == "TRIAL" ||
    walletData?.walletDisplayType == "TRIAL_EXPIRED";

  // trial user, wallet exists & is from a different team but same company, block top-up
  const topUpBlocked =
    trialUser &&
    walletData.walletId != null &&
    parseFloat(walletData.balance) != 0.0;
  const fetchMaxioCustomerId = async () => {
    const data = await GetMaxioCustomerID();
    setIsCustomerIdAvailable(
      data?.data?.message === ConstantTexts.MAXIO_GET_CUSTOMER_ID_SUCCESS
    );
  };

  useEffect(() => {
    fetchMaxioCustomerId();
  }, []);

  const handleMaxioBillingPortalRedirect = async () => {
    try {
      const data = await GetMaxioBillingPortalURL();

      // Check if there was an error in the response
      if (data.data?.code !== HTTP_STATUS.HTTP_OK) {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
          })
        );
        return;
      }

      // If no error, get the URL from the response and redirect
      const url = data?.data?.data?.url;

      if (url) {
        // Redirect to the URL in a new tab/window
        window.open(url, "_blank");
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
          })
        );
      }
    } catch (error) {
      // Handle any unexpected errors in the API call
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
        })
      );
    }
  };

  const manageSubscriptionApiCall = async () => {
    setLoading(true);
    const response = await CreateManageSubscriptionSession();
    const { status = 401, data = {} } = response;
    if (status === 200) {
      setLoading(false);
      window.open(data?.data, "_self");
    } else {
      setLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: response?.data?.message || "Something went wrong",
        })
      );
    }
  };

  return (
    <>
      {walletData?.walletDisplayType === "SELF_SERVE" ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <WalletWarningText c={"#163545"}>Plan</WalletWarningText>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Text14pxMedium>Self-serve</Text14pxMedium>
              <Text14pxMedium>
                {walletData?.autoRechargeEnabled
                  ? "Automatic wallet refill enabled"
                  : "Automatic wallet refill disabled"}
              </Text14pxMedium>
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <ActionButtonContainer
                u={"underline"}
                onClick={() => {
                  setDialogOpen(false);
                  navigate(RouteConstants.ROUTE_PRICING_PAGE);
                }}
              >
                Change Plan
              </ActionButtonContainer>
              <ActionButtonContainer
                u={"underline"}
                onClick={manageSubscriptionApiCall}
              >
                Manage Subscription
              </ActionButtonContainer>
            </Box>
          </PlanDetailsCardContainer>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <WalletWarningText c={"#163545"}>Plan</WalletWarningText>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Text14pxMedium>
                {walletData?.walletDisplayType === "UNLIMITED"
                  ? "Enterprise"
                  : "Trial"}
              </Text14pxMedium>
            </Box>
            {walletData?.walletDisplayType !== "UNLIMITED" && !topUpBlocked && (
              <Box sx={{ display: "flex", gap: "20px" }}>
                <ActionButtonContainer
                  u={"underline"}
                  onClick={() => {
                    navigate(RouteConstants.ROUTE_PRICING_PAGE);
                    setDialogOpen(false);
                  }}
                >
                  Go To Pricing
                </ActionButtonContainer>
              </Box>
            )}
          </PlanDetailsCardContainer>
        </Box>
      )}
      {/* Conditionally render Billing Portal URL button when isCustomerIdAvailable is true */}
      {isCustomerIdAvailable && (
        <TrestleButton
          onClick={handleMaxioBillingPortalRedirect} // Ensure this handler exists
          label="Billing Portal"
          type="primary"
          className="!h-[50px] !leading-[40px] !py-[20px] !pl-[20px] !pr-[20px] !w-fit !mt-[30px]"
        />
      )}
    </>
  );
};

export const PlanDetailsCardContainer = styled.div`
  width: 100%;
  height: 70px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
`;

export default BillingDetailsTab;
