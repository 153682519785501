import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { Box, Stack } from "@mui/material";
import TrestleButton from "../../Shared/TrestleButton";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  ComponentLabelHeader,
  MainHeaderText,
  Text16px,
  Text20pxBold,
  Text20pxMedium,
} from "../../Shared/CommonComponents";
import * as _ from "lodash";
import { ActionButtonContainer } from "../../Shared/PaymentWallet/WalletComponets/Styles";
import OverflowTooltip from "../../Shared/OverFlowToolTip";
import moment from "moment";
import { getUTCDate } from "../../../utils/helpers";
import {
  CircularCrossIcon,
  CircularGreenTickIcon,
  InQueueIcon,
} from "../../../assets/svgs";
import ProgressBar from "../../Shared/ProgressBar/ProgressBar";
import {
  downloadBatch,
  getBatchApiKeysAndProducts,
  getBatchFiles,
} from "../BatchUpload/BatchUploadService";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import { useAppDispatch } from "../../../app/hooks";
import TrestleTable from "../../Shared/TrestleTable/TrestleTable";
import RunHubSpotListPopUp from "./RunHubSpotListPopUp";
import {
  authenticateAndFetchAccessToken,
  checkifUserLoggedIn,
  fetchAllListsMetadataForUser,
  logoutHubspotUser,
} from "./HubSpotService";
import LoaderComponent from "../../Shared/LoaderComponent";
import { String } from "aws-sdk/clients/acm";
import { RouteConstants } from "../../../constants/RouteConstants";
import { GetWalletData } from "../../Shared/PaymentWallet/Services/PaymentWalletServices";
import { setWallet } from "../../../app/walletReducer";

const HubSpotIntegrationPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isHubSpotLoggedIn, setIsHubSpotLoggedIn] = useState<boolean>(false);
  const [hubSpotList, setHubSpotList] = useState<any[]>([]);
  const [intervalId, setIntervalId] = useState<any>();

  const [batchResultList, setBatchResultList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [hubSpotListPageNo, setHubSpotListPageNo] = useState(0);
  const [hubSpotListNoOfRecords, setHubSpotListNoOfRecords] = useState(20);
  const [hubspotListTotalNoOfRecords, setHubspotListTotalNoOfRecords] =
    useState(0);

  const [batchResultListPageNo, setBatchResultListPageNo] = useState(0);
  const [batchResultListNoOfRecords, setBatchResultListNoOfRecords] =
    useState(20);
  const [batchResultListTotalNoOfRecords, setBatchResultListTotalNoOfRecords] =
    useState(0);

  const [isRunHubSpotListPopUpOpen, setIsRunHubSpotListPopUpOpen] =
    useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<any>();

  const [hubSpotLoginUrl, setHubspotLoginUrl] = useState<string>("");
  const location = useLocation();
  const [apiKeysProducts, setApiKeysProducts] = useState<{}>({});

  const [hubspotPortalId, setHubspotPortalId] =
    useState<string>("Default portal ID");

  interface Product {
    apiName: string;
    displayName: string;
  }

  interface ApiKeysResponse {
    [apiKey: string]: Product[];
  }

  useEffect(() => {
    loadPage().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsRunHubSpotListPopUpOpen(false); // Close the popup
      }
    };

    getWalletData();

    document.addEventListener("keyup", handleEscape);

    return () => {
      document.removeEventListener("keyup", handleEscape);
    };
  }, []);

  const getWalletData = async () => {
    const response = await GetWalletData();
    const { status = 401, data = [] } = response;
    if (status === 200) {
      dispatch(setWallet(data?.data));
    } else {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Error fetching wallet data",
        })
      );
    }
  };

  const loadPage = async () => {
    setLoading(true);
    await fetchHubspotLoginDetails();

    await fetchAuthCode();

    await fetchApiKeysAndProducts();

    await fetchBatchExecutions();
  };
  useEffect(() => {
    const processingBatches = batchResultList.filter(
      (batch: any) =>
        batch.status === "PROCESSING" || batch.status === "IN_QUEUE"
    );

    if (processingBatches.length > 0) {
      if (intervalId != undefined) return;

      let interval = setInterval(async () => {
        if (!window.location.href.includes("/integration")) {
          clearInterval(interval);
        }

        let reqData: any = {
          pageNo: batchResultListPageNo,
          pageSize: batchResultListNoOfRecords,
          batchType: "HUBSPOT_BATCH",
        };
        const response = await getBatchFiles(reqData);
        const { status = 401, data = {} } = response || {};

        if (status === HTTP_STATUS.HTTP_OK)
          setBatchResultList(data.data.batchExecutions);
      }, 5000);
      setIntervalId(interval);
    } else {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [batchResultList]);

  useEffect(() => {
    const setHubspotLists = async () => {
      const payload = {
        pageNo: hubSpotListPageNo,
        pageSize: hubSpotListNoOfRecords,
      };
      const hubspotListResponse = await fetchAllListsMetadataForUser(payload);
      const { code = 401, data = {} } = hubspotListResponse || {};

      if (code === HTTP_STATUS.HTTP_OK) {
        setHubSpotList(hubspotListResponse?.data?.lists ?? []);
        setHubspotListTotalNoOfRecords(hubspotListResponse?.data?.total ?? 20);
      }
    };
    setHubspotLists();
  }, [isHubSpotLoggedIn, hubSpotListPageNo, hubSpotListNoOfRecords]);

  useEffect(() => {
    fetchBatchExecutions();
  }, [
    isRunHubSpotListPopUpOpen,
    batchResultListPageNo,
    batchResultListNoOfRecords,
  ]);

  const fetchBatchExecutions = async () => {
    let reqData: any = {
      pageNo: batchResultListPageNo,
      pageSize: batchResultListNoOfRecords,
      batchType: "HUBSPOT_BATCH",
    };
    const response = await getBatchFiles(reqData);
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK)
      setBatchResultList(data?.data?.batchExecutions);
    setBatchResultListTotalNoOfRecords(data?.data?.totalRecords);
  };

  const fetchApiKeysAndProducts = async () => {
    const apiKeysResponse = await getBatchApiKeysAndProducts();

    if (apiKeysResponse.status === HTTP_STATUS.HTTP_OK) {
      const rawData = apiKeysResponse.data.data;

      // Products to keep
      const allowedProducts = ["Phone Intel 3.0", "Real Contact 1.1"];

      // Filter data for all API keys
      const filteredData: ApiKeysResponse = Object.fromEntries(
        Object.entries(rawData)
          .map(([apiKey, products]) => [
            apiKey,
            (products as Product[]).filter((product) =>
              allowedProducts.includes(product.apiName)
            ),
          ])
          .filter(([api, product]) => product.length != 0)
      );

      // Update state with filtered data
      setApiKeysProducts(filteredData);
    }
  };

  const fetchHubspotLoginDetails = async () => {
    const response = await checkifUserLoggedIn();
    if (response.code === 200) {
      if (response.data.loginRequired === false) {
        setIsHubSpotLoggedIn(true);
        setHubspotPortalId(response.data.portalId ?? "Default");
      } else {
        setHubspotLoginUrl(`${response.data.url}`);
      }
    }
  };

  const logoutUserAccount = async () => {
    setLoading(true);
    await logoutHubspotUser();
    setIsHubSpotLoggedIn(false);
    setHubSpotList([]);
    fetchHubspotLoginDetails();
    setLoading(false);
  };

  const fetchAuthCode = async () => {
    // Extract the code from the URL
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      setLoading(true);
      try {
        const response = await authenticateAndFetchAccessToken(code);
        setIsHubSpotLoggedIn(true);
      } catch (err: any) {
        console.error("Error calling backend API:", err);
      } finally {
        setLoading(false);
      }
    }
  };

  const HubSpotListColumn = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "List Name",
      width: "35%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Uploaded On",
      width: "25%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <OverflowTooltip
            text={moment(getUTCDate(ele.createdAt || "")).format(
              "MM/DD/YYYY HH:mm:ss"
            )}
          >
            <span>
              {moment(getUTCDate(ele.createdAt || "")).format(
                "MM/DD/YYYY HH:mm:ss"
              )}
            </span>
          </OverflowTooltip>
        );
      },
      sortable: false,
    },
    {
      id: "additionalProperties.hs_list_size",
      numeric: false,
      disablePadding: false,
      label: "Number of Contacts",
      width: "20%",
      isCustomElements: true,
      customElements: (ele: any) => {
        // Safely access hs_list_size from additionalProperties
        return <span>{ele?.additionalProperties?.hs_list_size || "N/A"}</span>;
      },
      sortable: false,
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <TrestleButton
            className="!h-[50px] !w-[fit-content]"
            type={"primary"}
            label="Run List"
            onClick={() => handleRunClicked(ele)}
          />
        );
      },
      sortable: false,
    },
  ];

  const BatchCompletedList = [
    {
      id: "inputFile",
      numeric: false,
      disablePadding: false,
      label: "List Name",
      width: "35%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Uploaded On",
      width: "15%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <OverflowTooltip
            text={moment(getUTCDate(ele.createdAt || "")).format(
              "MM/DD/YYYY HH:mm:ss"
            )}
          >
            <span>
              {moment(getUTCDate(ele.createdAt || "")).format(
                "MM/DD/YYYY HH:mm:ss"
              )}
            </span>
          </OverflowTooltip>
        );
      },
      sortable: false,
    },
    {
      id: "completedAt",
      numeric: false,
      disablePadding: false,
      label: "Completion Date",
      width: "15%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <OverflowTooltip
            text={
              ele.completedAt
                ? moment(getUTCDate(ele.completedAt || "")).format(
                    "MM/DD/YYYY HH:mm:ss"
                  )
                : "-"
            }
          >
            <span>
              {ele.completedAt
                ? moment(getUTCDate(ele.completedAt || "")).format(
                    "MM/DD/YYYY HH:mm:ss"
                  )
                : "-"}
            </span>
          </OverflowTooltip>
        );
      },
      sortable: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      width: "15%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return getFileStatus(ele.status, ele.percentageCompleted);
      },
      sortable: true,
    },
    {
      id: "outputFile",
      numeric: false,
      disablePadding: false,
      label: "Output File",
      width: "20%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {ele.outputFile && ele.uuid ? (
              <div>
                <div style={{ marginBottom: "8px" }}>
                  <ActionButtonContainer
                    u={"underline"}
                    onClick={() => downloadFile(ele.uuid, ele.outputFile)}
                  >
                    {ele.outputFile}
                  </ActionButtonContainer>
                </div>
                {ele.leadAssessmentDone && (
                  <div style={{ marginTop: "8px" }}>
                    <ActionButtonContainer
                      u={"underline"}
                      onClick={() => {
                        const baseUrl =
                          process.env.REACT_APP_DEV_PORTAL_URL?.replace(
                            /\/+$/,
                            ""
                          );
                        const url = `${baseUrl}${RouteConstants.ROUTE_LEAD_ASSESSMENT_DETAILS.replace(
                          ":uuid",
                          ele.leadUuid
                        )}`;
                        window.open(url, "_blank", "noopener,noreferrer");
                      }}
                    >
                      Assessment Report
                    </ActionButtonContainer>
                  </div>
                )}
              </div>
            ) : (
              "-"
            )}
          </span>
        );
      },
      sortable: true,
    },
  ];

  const handleRunClicked = (list: any) => {
    setIsRunHubSpotListPopUpOpen(true);
    setSelectedList(list);
  };

  const downloadFile = async (uuid: string, fileName: string) => {
    try {
      setLoading(true);
      const response: any = await downloadBatch(uuid);
      setLoading(false);
      if (response.status !== HTTP_STATUS.HTTP_OK) {
        const { details } = response.data;
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: details,
          })
        );
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };
  const getFileStatus = (status: string, percentageCompleted: number) => {
    if (status === "FAILED") {
      return (
        <span className="flex gap-1" style={{ color: "#B23352" }}>
          {CircularCrossIcon} Failed
        </span>
      );
    } else if (status === "PROCESSING") {
      return <ProgressBar value={percentageCompleted} />;
    } else if (status === "COMPLETED" || status === "DELETED") {
      return (
        <span className="flex gap-1" style={{ color: "#00B47E" }}>
          {CircularGreenTickIcon} Completed
        </span>
      );
    } else if (status === "IN_QUEUE") {
      return (
        <span className="flex gap-1" style={{ color: "#0494B4" }}>
          {InQueueIcon} In queue
        </span>
      );
    } else return status;
  };

  return (
    <>
      <LoaderComponent loader={loading} />
      <Box>
        <Stack>
          {/* Header */}
          <Stack spacing={1} sx={{ px: "50px", pt: "50px" }}>
            <ComponentLabelHeader>
              HubSpot List Integration
            </ComponentLabelHeader>
            <Text16px>
              Connect Your HubSpot account and validate/verify contact with
              Trestle’s HubSpot Integration.
            </Text16px>
          </Stack>
          {/* Body */}
          <Stack padding={"50px"} spacing={4}>
            {/* step 1 */}
            <Stack spacing={3}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Text20pxMedium
                  c={"#FFFFFF"}
                  className={`flex items-center justify-center w-[32px] h-[32px] bg-[#0494B4] rounded-full`}
                >
                  1
                </Text20pxMedium>
                <Text20pxBold c={"#163545"}>Connect Account</Text20pxBold>
              </Stack>
              {isHubSpotLoggedIn ? (
                <>
                  <Box style={{ marginLeft: "50px" }}>
                    HubSpot App ID {hubspotPortalId}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior
                        logoutUserAccount(); // Call your logout function
                      }}
                      style={{
                        marginLeft: "48px",
                        textDecoration: "underline",
                        color: "#0494B4",
                        cursor: "pointer",
                      }}
                    >
                      Disconnect
                    </a>
                  </Box>
                </>
              ) : (
                <TrestleButton
                  type="primary"
                  label="Connect To HubSpot"
                  onClick={() => {
                    window.open(`${hubSpotLoginUrl}`, "_self");
                  }}
                  className="!w-[fit-content] !ml-[48px]"
                />
              )}
            </Stack>
            {/* step 2 */}
            <Stack spacing={3} style={{ marginTop: "48px" }}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Text20pxMedium
                  c={!isHubSpotLoggedIn ? "#555555" : "#FFFFFF"}
                  className={`flex items-center justify-center w-[32px] h-[32px] ${
                    !isHubSpotLoggedIn ? "bg-[#F5F5F5]" : "bg-[#0494B4]"
                  } rounded-full`}
                >
                  2
                </Text20pxMedium>
                <Text20pxBold c={!isHubSpotLoggedIn ? "#555555" : "#163545"}>
                  Select list and product
                </Text20pxBold>
              </Stack>
              {_.isEmpty(hubSpotList) ? (
                isHubSpotLoggedIn ? (
                  <Text16px c={"#163545"} className="!text-center">
                    No List Found In Logged In HubSpot Account
                  </Text16px>
                ) : (
                  <Text16px c={"#163545"} className="!text-center">
                    Connect to HubSpot to view lists to run
                  </Text16px>
                )
              ) : (
                <Box>
                  <TrestleTable
                    isPaginated={true}
                    headCells={HubSpotListColumn}
                    rows={hubSpotList}
                    setPageNumber={setHubSpotListPageNo}
                    totalNoOfRecords={hubspotListTotalNoOfRecords}
                    setNoOfRecords={setHubSpotListNoOfRecords}
                    loading={loading}
                  />
                </Box>
              )}
            </Stack>
            {/* step 3 */}
            <Stack spacing={3} style={{ marginTop: "48px" }}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Text20pxMedium
                  c={_.isEmpty(batchResultList) ? "#555555" : "#FFFFFF"}
                  className={`flex items-center justify-center w-[32px] h-[32px] ${
                    _.isEmpty(batchResultList) ? "bg-[#F5F5F5]" : "bg-[#0494B4]"
                  } rounded-full`}
                >
                  3
                </Text20pxMedium>
                <Text20pxBold
                  c={_.isEmpty(batchResultList) ? "#555555" : "#163545"}
                >
                  Download or export results
                </Text20pxBold>
              </Stack>
              {_.isEmpty(batchResultList) ? (
                <Text16px c={"#163545"} className="!text-center">
                  Connect to HubSpot and select lists to verify
                </Text16px>
              ) : (
                <Box>
                  <TrestleTable
                    isPaginated={true}
                    headCells={BatchCompletedList}
                    rows={batchResultList}
                    setPageNumber={setBatchResultListPageNo}
                    totalNoOfRecords={batchResultListTotalNoOfRecords}
                    setNoOfRecords={setBatchResultListNoOfRecords}
                    loading={loading}
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>
        <RunHubSpotListPopUp
          open={isRunHubSpotListPopUpOpen}
          apiKeysAndProducts={apiKeysProducts}
          setIsLoading={setLoading}
          callGetBatchAPI={() => {}}
          setOpen={setIsRunHubSpotListPopUpOpen}
          selectedList={selectedList}
        />
      </Box>
    </>
  );
};
export default HubSpotIntegrationPage;
