import React, { useEffect, useState } from "react";
import InputTextField from "./InputTextField";
import {
  Text16pxBold,
  Text16pxMedium,
  Text20pxBold,
  TextLabel,
} from "./CommonComponents";
import TrestleButton from "./TrestleButton";
import { InfoIcon, LockIconNew } from "../../assets/svgs";
import { AppConstants } from "../../constants/AppConstants";
import { MakeApiServiceCall } from "../UserMode/YourApiKey/YourApiKeyServices";
import { useAppDispatch } from "../../app/hooks";
import { handleMixPanel } from "../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../constants/MixPanelEvents";
import { Box } from "@mui/material";
import * as _ from "lodash";

interface ITestRequestFormProps {
  currentSelectedProduct: any;
  activeApiKey: string;
  setIsLoading: any;
  usageLimit: number;
  setResponse: any;
  setStatus: any;
  setInitialResponseData?: any;
  scrollToResponse?: any;
  setShowFeedback?: any;
  setFeedback?: any;
}

const NewTestRequestForm = ({
  currentSelectedProduct,
  activeApiKey,
  setIsLoading,
  usageLimit,
  setResponse,
  setStatus,
  setInitialResponseData = () => {},
  scrollToResponse = () => {},
  setShowFeedback = () => {},
  setFeedback = () => {},
}: ITestRequestFormProps) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({
    productName: "",
    email: "",
    phone: "",
    street_line_1: "",
    city: "",
    state_code: "",
    postal_code: "",
  });
  const [formError, setFormError] = useState<any>({
    productName: "",
    email: "",
    phone: "",
    street_line_1: "",
    city: "",
    state_code: "",
    postal_code: "",
  });

  const [tryForFree, setTryForFree] = useState<boolean>(false);
  const [quotaLimitReached, setQuotaLimitReached] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    setFormData({
      productName: "",
      email: "",
      phone: "",
      street_line_1: "",
      city: "",
      state_code: "",
      postal_code: "",
    });
    setTryForFree(false);
    setQuotaLimitReached(false);
  }, [currentSelectedProduct]);

  useEffect(() => {
    if (
      currentSelectedProduct.status === "CAN_REQUEST" ||
      currentSelectedProduct.status === "UNAVAILABLE" ||
      currentSelectedProduct.status === "REMOVE" ||
      currentSelectedProduct.status === "NOT_IN_USE" ||
      currentSelectedProduct.status === "REQUESTED" ||
      currentSelectedProduct.status === "DENIED" ||
      currentSelectedProduct.subscriptionStatus === false ||
      (usageLimit != 0 && usageLimit === currentSelectedProduct.usageCountV2)
    ) {
      setFormData({
        email: "waidong.syrws@email.com",
        phone: "206-973-5100",
        street_line_1: "100 Syrws St Ste 1",
        postal_code: "98264",
        state_code: "WA",
        city: "Lynden",
        productName: "Waidong Syrws",
      });
      setTryForFree(true);
    }
  }, [currentSelectedProduct]);

  const getSampleRequestStatus = () => {
    if (
      currentSelectedProduct.status === "CAN_REQUEST" ||
      currentSelectedProduct.status === "UNAVAILABLE" ||
      currentSelectedProduct.status === "REMOVE" ||
      currentSelectedProduct.status === "NOT_IN_USE" ||
      currentSelectedProduct.status === "REQUESTED" ||
      currentSelectedProduct.status === "DENIED" ||
      currentSelectedProduct.subscriptionStatus === false
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmit = async () => {
    setFeedback("");
    if (getSampleRequestStatus() || tryForFree) {
      handleMixPanel(MixPanelEvents.SUBMIT_TEST_REQUEST, {
        type: "Try For Free",
        productName: currentSelectedProduct.apiName,
      });
      switch (currentSelectedProduct.apiName) {
        case "Caller Identification 3.1":
          setResponse(
            AppConstants.DUMMY_API_RESPONSE["Caller Identification 3.1"]
          );
          break;
        case "Caller Identification 3.0":
          setResponse(
            AppConstants.DUMMY_API_RESPONSE["Caller Identification 3.0"]
          );
          break;
        case "Phone Intel 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Phone Intel 3.0"]);
          break;
        case "Real Contact 1.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Real Contact 1.1"]);
          break;
        case "Real Contact 1.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Real Contact 1.0"]);
          break;
        case "Reverse Phone 3.2":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Phone 3.2"]);
          break;
        case "Reverse Phone 3.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Phone 3.1"]);
          break;
        case "Reverse Phone 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Phone 3.0"]);
          break;
        case "Smart CNAM 3.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Smart CNAM 3.1"]);
          break;
        case "Phone Data 1.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Phone Data 1.0"]);
          break;
        case "Find Person 3.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Find Person 3.1"]);
          break;
        case "Find Person 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Find Person 3.0"]);
          break;
        case "Reverse Address 3.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Address 3.1"]);
          break;
        case "Reverse Address 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Address 3.0"]);
          break;
        case "Location Intel 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Location Intel 3.0"]);
          break;
      }
      setStatus("Success");
      scrollToResponse("response_container");
    } else {
      handleMixPanel(MixPanelEvents.SUBMIT_TEST_REQUEST, {
        type: "Make Test Request",
        productName: currentSelectedProduct.apiName,
      });
      setIsLoading(true);
      const row = currentSelectedProduct;
      const apiKey = activeApiKey;
      MakeApiServiceCall({ row, apiKey, formData })
        .then((data: any) => {
          if (!_.isEmpty(data.data)) {
            setResponse(data.data);
            setIsLoading(false);
            setStatus("Success");
            scrollToResponse("response_container");
            setShowFeedback(true);
          } else {
            setStatus("Failed");
            setResponse({ message: "No response from API" });
            scrollToResponse("response_container");
            setShowFeedback(true);
          }
        })
        .catch((error: any) => {
          setStatus("Failed");

          if (error?.response?.status === 429) {
            setQuotaLimitReached(true);
            setTryForFree(true);
            setFormData({
              email: "waidong.syrws@email.com",
              phone: "206-973-5100",
              street_line_1: "100 Syrws St Ste 1",
              postal_code: "98264",
              state_code: "WA",
              city: "Lynden",
              productName: "Waidong Syrws",
            });
            setInitialResponseData();
          } else if (
            error?.response?.status === 403 ||
            error?.response?.status === 500
          ) {
            setResponse(error?.response?.data);
          } else {
            setResponse({ message: "Internal Server Error" });
          }
          setIsLoading(false);
          scrollToResponse("response_container");
          setShowFeedback(true);
        });
    }
  };

  const handleTryRequestClick = () => {
    setTryForFree(true);
    setFormData({
      email: "waidong.syrws@email.com",
      phone: "206-973-5100",
      street_line_1: "100 Syrws St Ste 1",
      postal_code: "98264",
      state_code: "WA",
      city: "Lynden",
      productName: "Waidong Syrws",
    });
  };

  const showEmailField = () => {
    if (currentSelectedProduct.productName === "Real Contact API") {
      return true;
    } else {
      return false;
    }
  };

  const showRealContactAddressFields = () => {
    if (currentSelectedProduct.apiName === "Real Contact 1.1") {
      return true;
    } else {
      return false;
    }
  };

  const showPhoneField = () => {
    if (
      currentSelectedProduct.productName !== "Find Person API" &&
      currentSelectedProduct.productName !== "Reverse Address API" &&
      currentSelectedProduct.productName !== "Address Validation API"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showNameField = () => {
    if (
      currentSelectedProduct.productName === "Real Contact API" ||
      currentSelectedProduct.productName === "Find Person API"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showAddressFields = () => {
    if (
      currentSelectedProduct.productName === "Reverse Address API" ||
      currentSelectedProduct.productName === "Find Person API" ||
      currentSelectedProduct.productName === "Address Validation API" ||
      currentSelectedProduct.productName === "Real Contact API"
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    var element: any = document.getElementById(
      currentSelectedProduct.usagePlanId
    );
  }, [currentSelectedProduct.usagePlanId]);

  return (
    <>
      {currentSelectedProduct.usagePlanId ===
        currentSelectedProduct.usagePlanId && (
        <Box
          id={`${currentSelectedProduct.usagePlanId}`}
          className="flex flex-col gap-[20px] p-[30px] bg-[#FFFFFF]"
        >
          <Box className="flex items-center justify-between">
            <Text20pxBold c="#163545">Test Request</Text20pxBold>
            <Box className="flex gap-[5px] items-center">
              <Text16pxMedium>Queries used:</Text16pxMedium>
              <Text16pxBold>
                {usageLimit === 0
                  ? currentSelectedProduct?.usageCountV2
                  : `${currentSelectedProduct?.usageCountV2}/${usageLimit}`}
              </Text16pxBold>
            </Box>
          </Box>

          <Box className="flex flex-col gap-[20px]">
            <Box className="flex gap-[20px] w-full">
              {showNameField() && (
                <InputTextField
                  label={"Name"}
                  name={"productName"}
                  value={formData}
                  error={formError}
                  required={false}
                  onChange={handleChange}
                  className={`bg-[#F5F5F5] ${
                    currentSelectedProduct.productName === "Real Contact API"
                      ? "max-w-[full]"
                      : "max-w-[390px]"
                  }`}
                  disabled={getSampleRequestStatus() || tryForFree}
                  endAdornment={
                    getSampleRequestStatus() || tryForFree ? LockIconNew : null
                  }
                  height={"48px"}
                />
              )}

              {showPhoneField() && (
                <InputTextField
                  label={"Phone"}
                  name={"phone"}
                  value={formData}
                  error={formError}
                  height={"48px"}
                  required={false}
                  onChange={handleChange}
                  className={`bg-[#F5F5F5] ${
                    currentSelectedProduct.productName === "Real Contact API"
                      ? "max-w-[full]"
                      : "max-w-[390px]"
                  }`}
                  disabled={getSampleRequestStatus() || tryForFree}
                  endAdornment={
                    getSampleRequestStatus() || tryForFree ? LockIconNew : null
                  }
                />
              )}

              {showEmailField() && (
                <InputTextField
                  label={"Email"}
                  name={"email"}
                  value={formData}
                  error={formError}
                  required={false}
                  height={"48px"}
                  onChange={handleChange}
                  className={`bg-[#F5F5F5] ${
                    currentSelectedProduct.productName === "Real Contact API"
                      ? "max-w-[full]"
                      : "max-w-[390px]"
                  }`}
                  disabled={getSampleRequestStatus() || tryForFree}
                  endAdornment={
                    getSampleRequestStatus() || tryForFree ? LockIconNew : null
                  }
                />
              )}
            </Box>
            {showAddressFields() && (
              <Box className="flex gap-[20px] w-full">
                <InputTextField
                  label={"Street Line 1"}
                  name={"street_line_1"}
                  value={formData}
                  error={formError}
                  required={false}
                  height={"48px"}
                  onChange={handleChange}
                  className="bg-[#F5F5F5]"
                  disabled={getSampleRequestStatus() || tryForFree}
                  endAdornment={
                    getSampleRequestStatus() || tryForFree ? LockIconNew : null
                  }
                />

                <InputTextField
                  label={"City"}
                  name={"city"}
                  value={formData}
                  error={formError}
                  height={"48px"}
                  required={false}
                  onChange={handleChange}
                  className="bg-[#F5F5F5]"
                  disabled={getSampleRequestStatus() || tryForFree}
                  endAdornment={
                    getSampleRequestStatus() || tryForFree ? LockIconNew : null
                  }
                />
                <InputTextField
                  label={"State"}
                  name={"state_code"}
                  value={formData}
                  error={formError}
                  height={"48px"}
                  required={false}
                  onChange={handleChange}
                  className="bg-[#F5F5F5]"
                  disabled={getSampleRequestStatus() || tryForFree}
                  endAdornment={
                    getSampleRequestStatus() || tryForFree ? LockIconNew : null
                  }
                />
                <InputTextField
                  label={"Postal Code"}
                  name={"postal_code"}
                  height={"48px"}
                  value={formData}
                  error={formError}
                  required={false}
                  onChange={handleChange}
                  className="bg-[#F5F5F5]"
                  disabled={getSampleRequestStatus() || tryForFree}
                  endAdornment={
                    getSampleRequestStatus() || tryForFree ? LockIconNew : null
                  }
                />
              </Box>
            )}
          </Box>
          <Box>
            <TrestleButton
              onClick={handleSubmit}
              label="Submit"
              disabled={false}
              type="primary"
              className="mt-auto !w-[fit-content] !h-[48px]"
            />
          </Box>
          {quotaLimitReached ||
          (usageLimit != 0 &&
            usageLimit === currentSelectedProduct.usageCountV2) ? (
            <TextLabel className="flex gap-[5px] items-center mt-[10px]">
              <span>{InfoIcon}</span> You have reached your usage quota.{" "}
              <span
                className="underline text-[#0494B4] cursor-pointer font-bold"
                onClick={() => {
                  window.open("https://trestleiq.com/contact/");
                }}
              >
                Contact Us
              </span>{" "}
              to upgrade or{" "}
              <span
                className="underline text-[#0494B4] cursor-pointer font-bold"
                onClick={() => handleTryRequestClick()}
              >
                Try a Sample Request for Free
              </span>{" "}
              instead.
            </TextLabel>
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
};

export default NewTestRequestForm;
