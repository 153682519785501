import { useEffect, useState } from "react";
import { DateRangePicker as RDateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import InputTextField from "./InputTextField";
import Menu from "@mui/material/Menu";
import moment from "moment";

interface DateRangePickerProps {
  value: any;
  setValue: any;
  maxPastDays?: number; // Optional, defaults to calendar's default settings.
}

// Default value defined by the calendar's default settings.
const DEFAULT_MAX_PAST_DAYS = 10000;

function DateRangePicker({
  value,
  setValue,
  maxPastDays = DEFAULT_MAX_PAST_DAYS, // Default if not provided
}: DateRangePickerProps) {
  const [selectionRange, setSelectionRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const { startDate = new Date(), endDate = new Date() } = value;
    setSelectionRange([
      {
        startDate,
        endDate,
        key: "selection",
      },
    ]);
  }, []);

  const [selectedData, setSelectedDate] = useState<string>("");

  const handleSelect = (ranges: any) => {
    const { startDate = new Date(), endDate = new Date() } = ranges.selection;
    setSelectionRange([ranges.selection]);
    setSelectedDate(
      `${moment(startDate).format("MM/DD/YYYY")} - ${moment(endDate).format("MM/DD/YYYY")}`
    );
    setValue({ startDate, endDate });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Compute minDate: today's date minus maxPastDays
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - maxPastDays);
  const maxDate = new Date(); // restrict future selection if needed

  return (
    <>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="!cursor-pointer"
      >
        <InputTextField
          name={"date"}
          label={"Custom Date Range"}
          placeholder="MM/DD/YYYY - MM/DD/YYYY"
          value={{ date: selectedData }}
          error={{}}
          type={"text"}
          onChange={() => {}}
          disabled={false}
          height="50px"
          isCalendar={true}
        />
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{
          zIndex: 111112, // Ensure it's higher than the dialog box
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="mt-2"
      >
        <RDateRangePicker
          ranges={selectionRange}
          onChange={handleSelect}
          months={1}
          showDateDisplay={false}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Menu>
    </>
  );
}

export default DateRangePicker;
