import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LoaderComponent from "../../Shared/LoaderComponent";
import {
  ComponentLabelHeader,
  Text14pxBold,
  Text16pxMedium,
} from "../../Shared/CommonComponents";
import DropDown from "../../Shared/DropDown";
import {
  callExportCsvAPIV2,
  getProductsAndAPIkeys,
  getUsageDataV2,
} from "../../../http/subscription-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import DateRangeTrestle from "../../Shared/DateRangePicker";
import TrestleButton from "../../Shared/TrestleButton";
import moment from "moment";
import { useAppSelector } from "../../../app/hooks";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { Box, Grid2 } from "@mui/material";
import { getTimeDifference } from "../../../utils/timeDifference";
import QueryAnalysis from "../../UserDetails/QueryAnalysis";
import { TabTextComponent } from "../../Shared/ComponentDrawer/componentDrawer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const datasetsColors = [
  {
    borderColor: "#254b87",
    backgroundColor: "#254b87",
  },
  {
    borderColor: "#addc91",
    backgroundColor: "#addc91",
  },
  {
    borderColor: "#9daecc",
    backgroundColor: "#9daecc",
  },
  {
    borderColor: "#257675",
    backgroundColor: "#257675",
  },
  {
    borderColor: "#89b6b5",
    backgroundColor: "#89b6b5",
  },
  {
    borderColor: "#0072ce",
    backgroundColor: "#0072ce",
  },
  {
    borderColor: "#7eb7e8",
    backgroundColor: "#7eb7e8",
  },
  {
    borderColor: "#1fa040",
    backgroundColor: "#1fa040",
  },
  {
    borderColor: "#0494B4",
    backgroundColor: "#0494B4",
  },
  {
    borderColor: "#2c7cde",
    backgroundColor: "#2c7cde",
  },
  {
    borderColor: "#25fac1",
    backgroundColor: "#25fac1",
  },
];

interface YourUsageProps {
  userId?: string | number;
  userEmail?: string;
  usersDetails?: any;
  isAdmin?: boolean;
  setInitialFormdate?: any;
}

const TabOptions = ["Usage Chart", "Query Analysis"];

function YourUsageV2({
  userId = 0,
  userEmail = "",
  usersDetails = {},
  isAdmin = false,
  setInitialFormdate = () => {},
}: YourUsageProps) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [plotFilterData, setPlotFilterData] = useState<any>({
    products: ["All Products"],
    apikeys: [],
    timeline: "Daily",
    timeframe: "Last 30 days",
  });

  const [backPlotFilterData, setBackPlotFilterData] = useState<any>({
    products: [],
    apikeys: [],
    timeline: "Daily",
    timeframe: "",
  });

  const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });
  const [productOptions, setProductOptions] = useState<any>({});
  const [APIkeyOptions, setAPIkeyOptions] = useState<any>({});

  const [dateRage, setDateRage] = useState<any>({
    startTime: new Date(),
    endTime: new Date(),
  });

  const timeframeOptions = ["Last 7 days", "Last 30 days", "Custom range"];

  const numberDays: any = {
    "Last 7 days": 7,
    "Last 30 days": 30,
    "Custom range": null,
  };

  const [lastUpdatedDate, setLastUpdatedDate] = useState<any>();
  const [activeTab, setActiveTab] = useState<string>("Usage Chart");

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const userMode: any = useAppSelector(
    (state: any) => state.authReducer.userMode
  );

  useEffect(() => {
    setInitialFormdate();
    fetchProductAndAPIkeys();
    handleMixPanel(MixPanelEvents.VISIT_USER_USAGE_PAGE, {
      userId: userData?.id,
      userEmail: userData?.email,
    });

    if (isAdmin) {
      setActiveTab("Usage Chart");
    }
  }, []);

  const FetchUsageChartData = async () => {
    setIsLoading(true);
    const payload = getPayload();
    const response = await getUsageDataV2(payload);
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      setLastUpdatedDate(data.data.lastUpdatedAt);
      setChartData(formatChartData(data.data));
    } else {
      setIsLoading(false);

      dispatch(
        setToast({
          type: "error",
          message: data.message,
        })
      );
    }
  };

  const formatChartData = (data: any) => {
    const { labels, productUsageData } = data;

    let datasets: any = [];

    Object.keys(productUsageData).forEach((key: string, index: number) => {
      let temp = {
        label: key,
        data: productUsageData[key],
        total: productUsageData[key].reduce((a: number, b: number) => a + b, 0),
        ...datasetsColors[index],
      };
      datasets.push(temp);
    });

    return { labels, datasets };
  };

  const StoreAPIKeys = (data: any, setData: any) => {
    let tempMap: any = {};

    data.forEach((ele: any) => {
      Object.keys(ele).forEach((key: string) => {
        tempMap[ele[key]] = key;
      });
    });

    setData(tempMap);
  };

  const exportCSV = () => {
    setIsLoading(true);
    let payload = getPayload();

    callExportCsvAPIV2(payload)
      .then((response) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `trestle_api_key_per_product_usage_${Date.now()}.csv`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: "Not able to download CSV file",
          })
        );
      });
  };

  const fetchProductAndAPIkeys = async () => {
    setIsLoading(true);
    let response;
    if (userMode === "ADMIN") {
      response = await getProductsAndAPIkeys(userEmail);
    } else {
      response = await getProductsAndAPIkeys();
    }
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      StoreAPIKeys(data.data.apiKeys, setAPIkeyOptions);
      StoreAPIKeys(data.data.products, setProductOptions);
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const getPayload = () => {
    const { products, apikeys, timeframe, timeline } = plotFilterData;
    const { id = "" } = userData;

    let startEndData: any = LastNthDays(timeframe);

    let productArr = products.filter(
      (product: any) => product !== "All Products"
    );

    let payload = {
      userIds: [userId || id],
      productIds: productArr.map((ele: any) => {
        return productOptions[ele];
      }),
      apiKeyIds: apikeys.map((ele: any) => {
        return APIkeyOptions[ele];
      }),
      ...startEndData,
      usageType: timeline,
    };

    return payload;
  };

  useEffect(() => {
    let opt = Object.keys(APIkeyOptions);

    if (opt.length > 0) {
      setPlotFilterData({
        ...plotFilterData,
        apikeys: userId ? [...opt] : [opt[0]],
        products: ["All Products"],
      });
      FetchUsageChartData();
    }
  }, [APIkeyOptions]);

  const getProductsList = (data: any) => {
    let temp = [];
    if (data.length > 1 && data.includes("All Products")) {
      data.forEach((ele: any) => {
        if (ele !== "All Products") {
          temp.push(ele);
        }
      });
    } else {
      temp = data.length === 0 ? ["All Products"] : data;
    }
    return temp;
  };

  const LastNthDays = (timeframeO: string) => {
    let lastDays: number = numberDays[timeframeO];

    if (lastDays !== null) {
      let result = [];
      for (let i = 0; i < lastDays; i++) {
        let d = new Date();
        d.setDate(d.getDate() - i);
        result.push(d);
      }

      return {
        startTime: dateFormat(result[result.length - 1]),
        endTime: dateFormat(result[0]),
      };
    } else {
      const { startTime, endTime } = dateRage;

      return {
        startTime: dateFormat(startTime),
        endTime: dateFormat(endTime),
      };
    }
  };

  const dateFormat = (date: any) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <>
      <LoaderComponent
        loader={isLoading}
        msg={ConstantTexts.LONG_TIME_LOADING_TEXT}
      />
      {
        <>
          {!userId && (
            <div className="flex flex-col gap-[20px] px-[50px] pt-[50px] bg-[#F5F5F5]">
              <ComponentLabelHeader>Product Usage</ComponentLabelHeader>
              <Box className="flex items-top justify-between">
                <Box>
                  <Text16pxMedium>
                    Product Usage is based on the number of API requests made or
                    the number of records within the Batch Upload processed.
                  </Text16pxMedium>
                  <Text16pxMedium>
                    The usage is updated once every 15 minutes.
                  </Text16pxMedium>
                </Box>
                {lastUpdatedDate && (
                  <Text16pxMedium className="pl-[10px] text-right">
                    Last updated {getTimeDifference(lastUpdatedDate)} ago
                  </Text16pxMedium>
                )}
              </Box>
              <div className="flex">
                {TabOptions.map((tab: string) => {
                  return (
                    <TabTextComponent
                      selected={activeTab === tab}
                      key={tab}
                      onClick={() => {
                        setActiveTab(tab);
                      }}
                    >
                      {tab}
                    </TabTextComponent>
                  );
                })}
              </div>
            </div>
          )}
          <div
            className={`bg-white ${userId ? "w-full" : "p-[45px] w-[100%]"}`}
          >
            {usersDetails?.entityStatus == "DEACTIVATED" && isAdmin ? (
              <div className="w-[100%] p-[12px] bg-red-600 h-[50px] mb-[30px]">
                This User Is Deactivated and won't be able to log in.
              </div>
            ) : (
              <></>
            )}
            {activeTab === "Usage Chart" ? (
              <div className="w-full">
                <div className="flex w-full mb-[60px]">
                  {!userId && (
                    <>
                      <div className="max-w-[180px] w-full mr-2">
                        <DropDown
                          name={"products"}
                          placeholder={"All Products"}
                          label={"Product"}
                          value={getProductsList(plotFilterData["products"])}
                          error={""}
                          onChange={(val: any) => {
                            setPlotFilterData({
                              ...plotFilterData,
                              products: val,
                            });
                            setBackPlotFilterData({
                              ...backPlotFilterData,
                              products: [],
                            });
                          }}
                          required={false}
                          options={[
                            ...["All Products"],
                            ...(Object.keys(productOptions) || []),
                          ]}
                          className="!h-[50px] text-[#163545]"
                          multiSelect={true}
                        />
                      </div>

                      <div className="max-w-[500px] w-full mr-2">
                        <DropDown
                          name={"apikeys"}
                          label={"API Key"}
                          value={plotFilterData["apikeys"]}
                          error={""}
                          placeholder={"Search"}
                          onChange={(val: any) => {
                            setPlotFilterData({
                              ...plotFilterData,
                              apikeys: val,
                            });
                          }}
                          required={false}
                          options={Object.keys(APIkeyOptions) || []}
                          className="!h-[50px] text-[#163545]"
                          multiSelect={true}
                          truncateOption={true}
                        />
                      </div>
                    </>
                  )}
                  <div className="max-w-[180px] w-full mr-2">
                    <DropDown
                      name={"timeframe"}
                      label={"Timeframe"}
                      value={plotFilterData.timeframe}
                      error={""}
                      placeholder={"Search"}
                      onChange={(val: any) => {
                        const { timeframe, timeline } = plotFilterData;
                        setPlotFilterData({
                          ...plotFilterData,
                          timeframe: val,
                          timeline:
                            timeframe === "Custom range" ? timeline : "Daily",
                        });
                      }}
                      required={false}
                      options={timeframeOptions}
                      className="!h-[50px] text-[#163545]"
                    />
                  </div>

                  {plotFilterData.timeframe === "Custom range" && (
                    <div className="max-w-[280px] w-full mr-2">
                      <DateRangeTrestle
                        value={dateRage}
                        setValue={(val: any) => {
                          let timelineVal = "Daily";
                          setPlotFilterData({
                            ...plotFilterData,
                            timeline: timelineVal,
                          });

                          setDateRage({
                            startTime: val.startDate,
                            endTime: val.endDate,
                          });
                        }}
                      />
                    </div>
                  )}

                  <TrestleButton
                    onClick={() => {
                      FetchUsageChartData();
                      setBackPlotFilterData(plotFilterData);
                    }}
                    label="Apply Filter"
                    type="primary"
                    className="!h-[40px] !leading-[40px] !p-0 !min-w-[150px] !max-w-[150px] mt-auto ml-auto"
                  />

                  <TrestleButton
                    onClick={() => {
                      exportCSV();
                    }}
                    label="Export to CSV"
                    type="secondry"
                    className="!h-[40px] !leading-[40px] !p-0 !min-w-[120px] !max-w-[120px] mt-auto ml-4"
                  />
                </div>
                <Grid2 container spacing={2}>
                  <Grid2
                    size={{ sm: 9 }}
                    className="flex flex-col justify-center h-full gap-3"
                  >
                    <Line
                      id="LineUsage"
                      data={chartData}
                      options={AppConstants.USAGE_CHART_OPTIONS}
                    />
                  </Grid2>
                  <Grid2 size={{ sm: 3 }}>
                    <Box className="flex flex-col justify-center h-full gap-3">
                      {chartData.datasets.map((dataset: any, index: any) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 8,
                          }}
                        >
                          <Box
                            style={{
                              width: "48px",
                              height: "24px",
                              backgroundColor: dataset.backgroundColor,
                              marginRight: 8,
                            }}
                          />
                          <Box className="flex flex-col">
                            <Text14pxBold
                              className="!font-bold"
                              c={dataset.backgroundColor}
                            >
                              {dataset.label}
                            </Text14pxBold>
                            <Text14pxBold>
                              Total query volume:{" "}
                              {dataset.total.toLocaleString()}
                            </Text14pxBold>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid2>
                </Grid2>
              </div>
            ) : (
              <QueryAnalysis
                userId={userData?.id}
                setLoading={setIsLoading}
                isAdmin={false}
              />
            )}
          </div>
        </>
      }
    </>
  );
}

export default YourUsageV2;
