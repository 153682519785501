import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Text16px, Text16pxBold } from "../CommonComponents";
import * as _ from "lodash";

export interface TableHeaderProps {
  id: string;
  label: string | React.ReactNode;
  width?: string;
  colspan?: number;
  align?: "left" | "center" | "right";
  isSubHeader?: boolean;
  rowSpan?: number;
  background?: string;
  color?: string;
  mainHeader?: boolean;
  displayValue?: boolean;
  isCustomElement?: true;
  customElement?: any;
}

const headCells: TableHeaderProps[] = [
  {
    id: "details",
    label: "Details",
    width: "40%",
    rowSpan: 2,
    background: "#FFFFFF",
    color: "#163545",
    mainHeader: true,
    displayValue: true,
  },
  {
    id: "yourData",
    label: "Your Data",
    width: "20%",
    background: "#F5F5F5",
    color: "#163545",
    mainHeader: true,
    align: "center",
    colspan: 2,
    displayValue: false,
  },
  {
    id: "absolute",
    label: "Absolute",
    width: "10%",
    colspan: 1,
    align: "center",
    isSubHeader: true,
    background: "#F5F5F5",
    color: "#163545",
    mainHeader: false,
    displayValue: true,
  },
  {
    id: "relative",
    label: "Relative",
    width: "10%",
    colspan: 1,
    align: "center",
    isSubHeader: true,
    background: "#F5F5F5",
    color: "#163545",
    mainHeader: false,
    displayValue: true,
  },
  {
    id: "trestleBenchmark",
    label: "Trestle Benchmark",
    width: "20%",
    colspan: 1,
    align: "center",
    background: "#E5F4F7",
    color: "#163545",
    mainHeader: true,
    displayValue: false,
  },
  {
    id: "benchmark",
    label: "Relative",
    align: "center",
    width: "20%",
    colspan: 1,
    isSubHeader: true,
    background: "#E5F4F7",
    color: "#163545",
    mainHeader: false,
    displayValue: true,
  },
  {
    id: "difference",
    label: "Difference",
    align: "center",
    width: "10%",
    colspan: 1,
    rowSpan: 2,
    background: "#FFFFFF",
    color: "#163545",
    mainHeader: true,
    displayValue: true,
    isCustomElement: true,
    customElement: (row: any) => getDifferenceColor(row.title, row.difference),
  },
];

export const getDifferenceColor = (title: string, difference: string) => {
  const neutral_difference_array = [
    "phone_contact_grade_b",
    "phone_contact_grade_c",
    "email_contact_grade_b",
    "email_contact_grade_c",
  ];
  const positive_difference_green_array = [
    "phone_is_valid_true",
    "phone_contact_grade_a",
    "email_contact_grade_a",
    "phone_activity_score_less_than_70",
    "phone_line_type_mobile",
    "phone_to_name_coverage",
    "phone_to_name_to_address_coverage",
  ];
  const negative_difference_green_array = [
    "missing_input",
    "phone_is_valid",
    "phone_contact_grade_d",
    "phone_line_type_non_fixed_voIP",
    "phone_line_type_landline_and_fixed_voip",
    "phone_activity_score_less_than_30",
    "phone_line_type_tollfree",
    "email_is_valid_false",
    "email_contact_grade_d",
    "email_contact_grade_f",
    "address_is_valid_false",
    "duplicate_records",
    "duplicate_phone_records",
    "address_name_match_false",
    "phone_contact_grade_f",
  ];

  if (neutral_difference_array.includes(title) || difference === "N/A") {
    return difference;
  } else if (_.toInteger(difference.replace("%", "")) > 0) {
    if (positive_difference_green_array.includes(title)) {
      return <Text16px c={"#00B47E"}>{difference}</Text16px>;
    } else {
      return <Text16px c={"#B23352"}>{difference}</Text16px>;
    }
  } else {
    if (negative_difference_green_array.includes(title)) {
      return <Text16px c={"#00B47E"}>{difference}</Text16px>;
    } else {
      return <Text16px c={"#B23352"}>{difference}</Text16px>;
    }
  }
};

const CustomTable = ({ rows }: any) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", boxShadow: "none", fontFamily: "DM Sans" }}>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell, j) => {
                  if (headCell.mainHeader) {
                    return (
                      <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sx={{
                          width: headCell.width,
                          backgroundColor: headCell.background,
                          color: headCell.color,
                          fontFamily: "DM Sans",
                          fontSize: "16px",
                          pb: "5px",
                          pl: j === 0 ? "0px" : "16px",
                          borderBottom: headCell.displayValue
                            ? "1px solid #E5E5E5"
                            : "none",
                        }}
                        colSpan={headCell.colspan}
                        rowSpan={headCell.rowSpan}
                      >
                        <Text16pxBold c={"#00485A"} style={{ fontWeight: 700 }}>
                          {headCell.label}
                        </Text16pxBold>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
              <TableRow>
                {headCells.map((headCell) => {
                  if (!headCell.mainHeader && headCell.isSubHeader) {
                    return (
                      <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sx={{
                          width: headCell.width,
                          backgroundColor: headCell.background,
                          color: headCell.color,
                          fontFamily: "DM Sans",
                          fontSize: "16px",
                          borderBottom: headCell.isSubHeader
                            ? "1px solid #E5E5E5"
                            : "none",
                        }}
                        colSpan={headCell.colspan}
                        rowSpan={headCell.rowSpan}
                      >
                        <Text16pxBold c={"#00485A"}>
                          {headCell.label}
                        </Text16pxBold>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row: any, index: any) => (
                <TableRow key={index}>
                  {headCells.map((headCell: any, j: any) => {
                    if (headCell.displayValue) {
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontFamily: "DM Sans",
                            width: headCell.width,
                            fontWeight: 400,
                            fontSize: "16px",
                            backgroundColor: headCell.background,
                            textAlign: headCell.align,
                            color: headCell.color,
                            pl: j === 0 ? "0px" : "16px",
                            borderBottom:
                              index !== rows?.length - 1
                                ? "1px solid #E5E5E5"
                                : "none",
                          }}
                        >
                          {headCell.isCustomElement
                            ? headCell.customElement(row)
                            : row[headCell.id as keyof typeof row]}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CustomTable;
