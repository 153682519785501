import { AppConstants } from "../constants/AppConstants";
import { RegExConstants } from "../constants/RegExConstants";
import {
  SelfServeFormDataProps,
  SelfServeFormErrorProps,
} from "../constants/SelfServeInterfaces";

export const validationEmail = (email: string) => {
  let err = "";
  if (!email.trim()) {
    err = AppConstants.REQUIRED_FILED_ERROR;
  } else if (!RegExConstants.EMAIL_VALIDATE.test(email)) {
    err = AppConstants.EMAIL_ERROR_MSG;
  }
  return err;
};

export const validatePhone = (phone: string, type?: string) => {
  let err = "";
  if (type === "SIGNUP" && !phone?.trim()) {
    err = AppConstants.REQUIRED_FILED_ERROR;
  } else if (
    phone &&
    (!RegExConstants.PHONE_VALIDATE.test(phone) || phone.trim().length < 7)
  ) {
    err = AppConstants.PHONE_ERROR_MSG;
  }
  return err;
};

export const validatePassword = (password: string) => {
  let err = "";
  if (!password.trim()) {
    err = AppConstants.REQUIRED_FILED_ERROR;
  } else if (!RegExConstants.PASSWORD_POLICY.test(password)) {
    err = AppConstants.PASSWORD_ERROR_MSG;
  }
  return err;
};

// Format Text
export const formatName = (name: string, noSpace: boolean = false) => {
  const REGEX = noSpace
    ? RegExConstants.NO_SPACE_IN_TEXT
    : RegExConstants.ACCEPT_SPACE_IN_TEXT;
  const formatedName = name.replace(REGEX, "");
  return formatedName;
};

export const formatPhone = (phone: string) => {
  if (phone.trim() && RegExConstants.PHONE_VALIDATE.test(phone)) {
    const txt = phone;
    return txt;
  }
  return "";
};

export const validateWalletAmount = (
  formData: SelfServeFormDataProps,
  walletData: any
) => {
  const amount = parseFloat(formData.amount as string);
  const minBalance = 25 - parseFloat(walletData?.balance || "0");
  const minAmount = minBalance > 25 ? minBalance : 25;
  const maxAmount = minBalance > 25 ? minBalance + 250 : 250;

  if (amount < minAmount || amount > maxAmount) {
    return AppConstants.WALLET_AMOUNT.replace(
      "{maxBalance}",
      `$${maxAmount.toFixed(2).toString()}`
    ).replace("{minBalance}", `$${minAmount.toFixed(2).toString()}`);
  } else if (
    parseFloat(walletData?.balance || "0") < 0 &&
    (amount <= minBalance || formData.amount === "")
  ) {
    return `Please input a value greater than $${minBalance.toFixed(
      2
    )} to achieve the minimum recommended wallet balance.`;
  } else {
    return "";
  }
};

export const validateWalletBalanceThreshold = (
  formData: SelfServeFormDataProps
) => {
  const threshold = parseFloat(formData?.balanceThreshold as string);
  const targetBalance = parseFloat(formData?.targetBalance as string);

  if (threshold < 10 || threshold > 250) {
    return AppConstants.WALLET_THRESHOLD_AMOUNT;
  } else if (formData?.targetBalance && threshold > targetBalance) {
    return "The threshold amount should be less than the refill amount.";
  } else {
    return "";
  }
};

export const validateWalletTargetBalance = (
  formData: SelfServeFormDataProps
) => {
  const targetBalance = parseFloat(formData?.targetBalance as string);
  const balanceThreshold = parseFloat(formData?.balanceThreshold as string);

  if (targetBalance < 25 || targetBalance > 250) {
    return AppConstants.WALLET_AUTO_RECHARGE_AMOUNT;
  } else if (
    formData?.balanceThreshold &&
    (targetBalance < balanceThreshold || targetBalance - balanceThreshold < 10)
  ) {
    return "The refill amount should be atleast $10 more than the threshold.";
  } else {
    return "";
  }
};

export const formatTags = (input: string): string => {
  const words = input.split("#");

  return words.join(" #").replace(/\s+/g, " ").trim();
};
