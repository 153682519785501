import BaseAPIClient from "./api";
import { AppConstants } from "../constants/AppConstants";

const globalHeaders: any = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const API_BASE_URL: string =
    process.env.REACT_APP_API_URL || "http://localhost";
  const API = BaseAPIClient?.axiosInstance;

export const registerIp = async (email: any, event: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.REGISTER_IP}?email=${encodeURIComponent(email)}&event=${encodeURIComponent(event)}`;
  const response = await API.put(uri, {
    headers,
  });
  return response;
};

export const getUserVerificationData = async (email: any) => {
  console.log("getUserVerificationData....", email);
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.USER_VERIFICATION_DATA}?email=${encodeURIComponent(email)}`;
  const response = await API.get(uri, {
    headers,
  });
  return response;
};