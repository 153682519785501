import {
  Navigate,
  Route,
  Routes as RoutesReactRouterDom,
} from "react-router-dom";
import { RouteConstants } from "../constants/RouteConstants";
import { Suspense, lazy } from "react";
import ProductAccess from "../components/AdminMode/ProductAccess/ProductAccess";
import AllUsers from "../components/AdminMode/AllUsers/index";
import YourUsage from "../components/UserMode/YourUsage/YourUsage";
import YourApiKey from "../components/UserMode/YourApiKey/YourApiKey";
import AdminUsage from "../components/AdminMode/AdminUsage/AdminUsage";
import PricingPage from "../components/UserMode/PricingPage";
import YourApiKeyNew from "../components/UserMode/YourApiKey/YourApiKeyNew";
import BatchUploadAdmin from "../components/AdminMode/BatchUploadsAdmin/BatchUploadAdmin";
import TrestleAppBar from "../components/Shared/Appbar/Appbar";
import PublicPagesLayout from "../components/Shared/PublicLayout";
import LeadAssessmentForm from "../components/LeadAssessment";
import LeadAssessmentReportPage from "../components/LeadAssessment/LeadAssessmentReport";

export const AdminRoutes = () => {
  return (
    <Suspense>
      <RoutesReactRouterDom>
        <Route element={<TrestleAppBar />}>
          <Route
            path={RouteConstants.ROUTE_ADMIN_ALL_USERS}
            element={<AllUsers />}
          />

          <Route
            path={RouteConstants.ROUTE_ADMIN_PRODUCT_ACCESS}
            element={<ProductAccess />}
          />

          <Route
            path={RouteConstants.ROUTE_ADMIN_BATCH_UPLOAD}
            element={<BatchUploadAdmin />}
          />
          <Route
            path={RouteConstants.ROUTE_ADMIN_USAGES}
            element={<AdminUsage />}
          />

          {/* <Route
            path={RouteConstants.ROUTE_YOUR_USAGE}
            element={<YourUsage />}
          />
          <Route
            path={RouteConstants.ROUTE_YOUR_API_KEY}
            element={<YourApiKeyNew />}
          />

          <Route
            path={RouteConstants.ROUTE_PRICING_PAGE}
            element={<PricingPage />}
          /> */}

          <Route
            path={"*"}
            element={<Navigate to={RouteConstants.ROUTE_ADMIN_HOME} />}
          />
        </Route>
        <Route element={<PublicPagesLayout />}>
          <Route
            path={RouteConstants.ROUTE_LEAD_ASSESSMENT_FORM}
            element={<LeadAssessmentForm />}
          />
          <Route
            path={RouteConstants.ROUTE_LEAD_ASSESSMENT_DETAILS}
            element={<LeadAssessmentReportPage />}
          />
        </Route>
      </RoutesReactRouterDom>
    </Suspense>
  );
};
