import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  APIKEY_LABEL,
  ComponentLabelHeader,
  Text16pxBold,
  Text16pxMedium,
  TextLabel,
  TextValue,
} from "../../Shared/CommonComponents";
import InputTextField, { InputErrortext } from "../../Shared/InputTextField";
import TrestleButton from "../../Shared/TrestleButton";
import LoaderComponent from "../../Shared/LoaderComponent";
import {
  getSubscriptions,
  getRevokedApiKeys,
} from "../../../http/authentication-service";
import { changeSubscriptionName } from "../../../http/subscription-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import { useDispatch } from "react-redux";
import NoDataFound from "../../Shared/NoDataFound";
import { useAppSelector } from "../../../app/hooks";
import RevokeAPIKey from "../../RevokeApiKey/RevokeApiKey";
import { Box, Stack, Tooltip } from "@mui/material";
import { PencilIcon, TrestleIcon } from "../../../assets/svgs";
import { getAllProducts } from "../../../http/subscription-service";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import Alerttriangle from "../../../assets/Alert-Triangle.svg";
import { isEmpty } from "lodash";
import { RouteConstants } from "../../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import { setWallet } from "../../../app/walletReducer";
import { GetWalletData } from "../../Shared/PaymentWallet/Services/PaymentWalletServices";
import { Helmet } from "react-helmet";
import NewAccordions from "../../Shared/NewAccordion/NewAccordion";
import ApiKeyProductsListCard from "../../Shared/ApiKeyProductsListCard";
import { ActionButtonContainer } from "../../Shared/PaymentWallet/WalletComponets/Styles";
import { expiryDate } from "../../../utils/helpers";
import { setLoader } from "../../../app/loaderReducer";

function YourApiKeyNew() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>("");
  const [ApiKeyList, setApiKeyList] = useState<any[]>([]);
  const [revokedApiKeyList, setRevokedApiKeyList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expandedApiKeyPlan, setExpandedApiKeyPlan] = useState<string>("");
  const [editKeyName, setEditKeyName] = useState<boolean>(false);
  const [newKeyName, setNewKeyName] = useState<any>({
    subscriptionId: "",
    keyName: "",
  });

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const walletData: any = useAppSelector(
    (state: any) => state.walletReducer.walletData
  );

  const updateSubscriptionName = async (id: any, name: string) => {
    setIsLoading(true);
    const response = await changeSubscriptionName(id, name);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      // setIsLoading(false);
      await fetchApiKeyList().then(() => {
        setIsLoading(false);
      });
      setEditKeyName(false);
      setNewKeyName({
        subscriptionId: "",
        keyName: "",
      });
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: "Subscription name updated successfully",
        })
      );
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const fetchProducts = async () => {
    setIsLoading(true);
    const response = await getAllProducts();
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      // setIsLoading(false);
      let temp: any = {};
      Object.keys(data.data).forEach((key: string) => {
        return (temp[data.data[key]] = key);
      });
      let d: any = Object.keys(temp).sort();
      // setSelectedProduct(d.length ? d[0] : "");
    } else {
      // setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const fetchApiKeyList = async () => {
    setIsLoading(true);
    const response = await getSubscriptions();
    const { status = 400, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setApiKeyList(data.data.subscriptions);
      setRevokedApiKeyList(data.data.revokedKeys);
      setExpandedApiKeyPlan(data?.data?.subscriptions[0]?.planType);
    } else {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const fetchRevokedApiKeyList = async () => {
    setIsLoading(true);
    const response = await getRevokedApiKeys();
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setRevokedApiKeyList(data.data);
      // setIsLoading(false);
    } else {
      // setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const [loaderText, setLoaderText] = useState<any>("");

  const init = async () => {
    await fetchApiKeyList();
    // await fetchRevokedApiKeyList();
    // await fetchProducts();

    setExpanded(`panel${0}`);
    if (!isEmpty(userData)) {
      handleMixPanel(MixPanelEvents.VISIT_YOURAPIKEY_PAGE, {
        userId: userData?.id,
        userEmail: userData?.email,
      });
    }
  };

  const GetWalletDataAPICall = async () => {
    setIsLoading(true);
    dispatch(setLoader({ open: true }));
    const PaymentSuccess = new URLSearchParams(window.location.search).get(
      "paymentSuccess"
    );
    try {
      if (PaymentSuccess !== "true") {
        const response = await GetWalletData();
        const { status = 401, data = [] } = response;
        if (status === 200) {
          dispatch(setWallet(data?.data));
          init().then(() => {
            setIsLoading(false);
            dispatch(setLoader({ open: false }));
          });
        } else {
          dispatch(
            setToast({
              open: true,
              severity: AppConstants.TOAST_ERROR,
              msg: "Error fetching wallet data",
            })
          );
          init().then(() => {
            setIsLoading(false);
            dispatch(setLoader({ open: false }));
          });
          Sentry.captureMessage(
            "Error Fetching wallet Data :: " + userData?.email,
            "error"
          );
        }
      } else {
        let count = 0;
        const myInterval = setInterval(async () => {
          const response = await GetWalletData();
          const { status = 401, data = [] } = response;
          if (status === 200) {
            if (count === 16) {
              localStorage.setItem("paymentSuccess", "false");
              // setWalletData(data);
              dispatch(setWallet(data?.data));
              init().then(() => {
                setIsLoading(false);
                dispatch(setLoader({ open: false }));
              });
              clearInterval(myInterval);
              dispatch(
                setToast({
                  open: true,
                  severity: AppConstants.TOAST_ERROR,
                  msg: "Plan upgradation Failed Please contact support",
                })
              );
              count = 0;
              Sentry.captureMessage(
                "Plan upgradation failed for user with email :: " +
                  userData?.email,
                "error"
              );
            } else {
              count += 2;
            }
            if (PaymentSuccess === "true") {
              if (data?.data?.walletDisplayType === "SELF_SERVE") {
                window.dataLayer.push({
                  //Added GMT tag after checkout successful
                  event: "purchase",
                  userId: userData?.id,
                  ecommerce: {
                    currency: "USD",
                    items: [
                      {
                        item_name: "api credit",
                        price: data?.data?.balance,
                      },
                    ],
                  },
                });

                if (
                  walletData?.walletDisplayType === "TRIAL" ||
                  walletData?.walletDisplayType === "TRIAL_EXPIRED"
                ) {
                  handleMixPanel(MixPanelEvents.PLAN_UPGRADE_SUCCESS, {
                    userEmail: userData?.email,
                    walletAmount: response?.data?.balance,
                    autoRechargeThreshold: response?.data?.balanceThreshold,
                    autoRechargeAmount: response?.data?.targetBalance,
                  });
                }

                localStorage.setItem("paymentSuccess", "false");
                // setWalletData(data);
                dispatch(setWallet(data?.data));
                init().then(() => {
                  setIsLoading(false);
                  dispatch(setLoader({ open: false }));
                });
                clearInterval(myInterval);
              }
            } else {
              localStorage.setItem("paymentSuccess", "false");
              // setWalletData(data);
              dispatch(setWallet(data?.data));
              init().then(() => {
                setIsLoading(false);
                dispatch(setLoader({ open: false }));
              });
              clearInterval(myInterval);
            }
          } else {
            localStorage.setItem("paymentSuccess", "false");
            init().then(() => {
              setIsLoading(false);
              dispatch(setLoader({ open: false }));
            });
            clearInterval(myInterval);
            Sentry.captureMessage(
              "Error Fetching wallet Data :: " + userData?.email,
              "error"
            );
          }
        }, 2000);
      }
    } catch (error) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Error fetching wallet data",
        })
      );
      init().then(() => {
        setIsLoading(false);
        dispatch(setLoader({ open: false }));
      });
      Sentry.captureMessage(
        "Error Fetching wallet Data :: " + userData?.email,
        "error"
      );
    }
  };

  useEffect(() => {
    window.SetMode("USER");
    GetWalletDataAPICall();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trestle Developer Portal</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DEV_PORTAL_URL}api-keys`}
        />
      </Helmet>
      <LoaderComponent loader={isLoading} msg={loaderText} />

      <div>
        <div className="flex-col p-[50px] gap-[15px] bg-[#F5F5F5]">
          <ComponentLabelHeader className="flex-1">
            Welcome To Trestle, {userData?.firstName || "User"}
          </ComponentLabelHeader>
          <div className="flex justify-between">
            <Text16pxMedium className="w-[70%]">
              Trestle provides next-generation identity APIs, enabling seamless
              customer connections and maximizing contactability,
              identification, and operational efficiency. Get started by testing
              out any of our APIs below.
            </Text16pxMedium>{" "}
            <div className="flex gap-[10px]">
              {expandedApiKeyPlan && (
                <>
                  <Text16pxMedium>Subscription Plan:</Text16pxMedium>{" "}
                  <Text16pxBold className="!font-bold">
                    {expandedApiKeyPlan
                      .toLowerCase()
                      .replace(/_/g, "-")
                      .replace(/^\w/, (c: string) => c.toUpperCase())}
                  </Text16pxBold>{" "}
                </>
              )}
            </div>
          </div>
        </div>

        <div>
          {ApiKeyList.map((ele: any, index: number) => {
            const {
              apiKeyName = "",
              lastUsageUpdatedDate = "",
              apiKey = "",
              planType = "",
              qps = "",
              limit = "",
              products = [],
              status = "",
              apiKeyEnabled = false,
            } = ele || {};
            let totalUsage: number = 0;
            const productStatus: { [key: string]: any } = {};

            products.forEach((product: any) => {
              totalUsage += product.usageCount;
              if (productStatus[product.productName] === "AVAILABLE") {
                productStatus[product.productName] = "AVAILABLE";
              } else {
                productStatus[product.productName] = product.status;
              }
            });

            // VERSION 2
            let totalUsageV2: number = 0;
            products.forEach((product: any) => {
              totalUsageV2 += product.usageCountV2;
            });
            let remainingUsage: number =
              limit === 0 ? -1 : limit - totalUsageV2;
            let expiryData: any = expiryDate(ele.expiryDate, ele.creationDate);
            let isEnterprise: any = planType === "ENTERPRISE";
            let subscriptionError: any =
              status === "EXPIRED"
                ? "This API key has expired."
                : !apiKeyEnabled
                ? "This API key is disabled."
                : "";

            const panel = `panel${index}`;
            const noOfKeys = ApiKeyList.length;

            return (
              <NewAccordions
                key={`key-accor-${ele}`}
                expanded={expanded}
                setExpanded={(val: any) => {
                  if (expanded !== panel) {
                    setExpandedApiKeyPlan(planType);
                    setExpanded(panel);
                    setNewKeyName({
                      subscriptionId: "",
                      keyName: "",
                    });
                  } else if (val === "close") {
                    setExpanded(val);
                    setNewKeyName({
                      subscriptionId: "",
                      keyName: "",
                    });
                  }
                }}
                index={index}
                disabled={null}
                summary={
                  <div
                    className={`flex w-full items-center justify-between ${
                      noOfKeys === 1 ? "pb-[20px]" : ""
                    }`}
                  >
                    <Box className="flex items-center">
                      <APIKEY_LABEL className="max-w-[400px] w-fit">
                        {expanded === `panel${index}` &&
                        newKeyName.subscriptionId === ele.subscriptionId ? (
                          <div className="flex">
                            <div className="w-full">
                              <InputTextField
                                name={"keyName"}
                                label={""}
                                value={newKeyName}
                                error={{}}
                                type={"text"}
                                onChange={(e: any) => {
                                  setNewKeyName({
                                    ...newKeyName,
                                    keyName: e.target.value,
                                  });
                                }}
                                disabled={
                                  !(
                                    newKeyName.subscriptionId ===
                                    ele.subscriptionId
                                  )
                                }
                                copyToClipboard={false}
                                height={"40px"}
                                maxLength={AppConstants.MAX_APIKEY_NAME_LENGTH}
                              />
                            </div>
                            <div className="px-3">
                              {!editKeyName ? (
                                <div className="mt-[5px]">{PencilIcon}</div>
                              ) : (
                                newKeyName.keyName.trim() &&
                                newKeyName.keyName !== apiKeyName && (
                                  <TrestleButton
                                    onClick={() => {
                                      updateSubscriptionName(
                                        newKeyName.subscriptionId,
                                        newKeyName.keyName
                                      );
                                    }}
                                    label="Save"
                                    type="secondry"
                                    className="!h-[40px] !leading-[40px] !p-0 !px-5 !min-w-fit"
                                  />
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="flex">
                            {apiKeyName || `Your Trial API Key {index + 1}`}
                            {expanded === `panel${index}` && (
                              <Tooltip title="Edit API Key name">
                                <div
                                  className="mt-[6px] ml-1 cursor-pointer"
                                  onClick={() => {
                                    setEditKeyName(true);
                                    setNewKeyName({
                                      subscriptionId: ele.subscriptionId,
                                      keyName: apiKeyName,
                                    });
                                  }}
                                >
                                  {PencilIcon}
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        )}
                      </APIKEY_LABEL>
                      {subscriptionError && expanded !== `panel${index}` && (
                        <InputErrortext className="flex gap-[5px] !text-[14px] ml-[26px] items-center">
                          <img
                            src={Alerttriangle}
                            alt="required"
                            style={{ width: "20px", height: "20px" }}
                          />
                          {subscriptionError}
                        </InputErrortext>
                      )}
                    </Box>

                    {planType !== "TRIAL" && (
                      <div
                        className="flex items-center gap-[10px]"
                        key={"quota"}
                      >
                        <TextLabel>Quota:</TextLabel>
                        <TextValue>
                          {limit == null || limit === 0
                            ? "Unlimited"
                            : typeof limit === "string"
                            ? `${limit
                                .toLowerCase()
                                .replace(/_/g, "-")
                                .replace(/^\w/, (c: string) =>
                                  c.toUpperCase()
                                )} Queries /Month`
                            : `${String(limit)} Queries /Month`}
                        </TextValue>
                      </div>
                    )}
                  </div>
                }
                details={
                  <div
                    className={`w-full ${noOfKeys === 1 ? "pb=[40px]" : ""}`}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div className="flex" style={{ alignItems: "center" }}>
                        <Text16pxBold
                          style={{
                            width: "70px",
                            height: "60px",
                            display: "flex",
                            backgroundColor: subscriptionError
                              ? "#F4E1E5"
                              : "#F5F5F5",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "default",
                            fontWeight: "500",
                            paddingLeft: "10px",
                            color: subscriptionError ? "#B2335299" : "#555555",
                          }}
                        >
                          API Key
                        </Text16pxBold>
                        <InputTextField
                          name={"apikey"}
                          label={""}
                          value={{ apikey: apiKey }}
                          error={{ apikey: subscriptionError ? " " : "" }}
                          type={"text"}
                          onChange={() => {}}
                          disabled={true}
                          copyToClipboard={true}
                          className="!w-[670px] !pr-0"
                          disabledStyle="!c-[#163545]"
                          inputStyle={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              "-webkit-text-fill-color": subscriptionError
                                ? "#B23352"
                                : "#163545 !important",
                            },
                          }}
                          endAdornmentStyle={{
                            background: subscriptionError
                              ? "#B233521A"
                              : "rgb(229, 229, 229)",
                            color: subscriptionError ? "#B2335299" : "#555555",
                            height: "60px",
                            pl: "15px",
                            maxHeight: "60px",
                            margin: "0px",
                            justifyContent: "center",
                          }}
                        />
                      </div>
                      {subscriptionError && (
                        <InputErrortext className="flex mt-[14px] gap-[5px] !text-[14px] items-center">
                          <img
                            src={Alerttriangle}
                            alt="required"
                            style={{ width: "20px", height: "20px" }}
                          />
                          {subscriptionError}
                          <ActionButtonContainer
                            onClick={() =>
                              window.open("https://trestleiq.com/contact/")
                            }
                            className="!underline"
                          >
                            Contact Us
                          </ActionButtonContainer>{" "}
                          to restore it
                        </InputErrortext>
                      )}
                    </Box>
                    {planType === "TRIAL" && expiryData >= 0 && (
                      <div className="w-[100%] bg-[#E5F4F7] items-center flex my-[30px] h-[80px] px-[20px] justify-between">
                        <div className="flex gap-[20px] items-center">
                          {TrestleIcon}
                          {expiryData <= 0 ? (
                            <div>
                              <span className="text-[#00485A] font-bold">
                                {" "}
                                Your trial has ended.
                              </span>{" "}
                              Upgrade now to regain access and receive more test
                              queries.
                            </div>
                          ) : (
                            <div>
                              <span className="text-[#00485A] font-bold">
                                {" "}
                                Your trial is ending soon.
                              </span>{" "}
                              You have {expiryData} days remaining on Trial
                            </div>
                          )}
                        </div>

                        <TrestleButton
                          onClick={() => {
                            navigate(RouteConstants.ROUTE_PRICING_PAGE);
                            handleMixPanel(
                              MixPanelEvents.WALLET_UPGRADE_NOW_BANNER,
                              {
                                userEmail: userData?.email,
                              }
                            );
                          }}
                          label="Upgrade Now"
                          type="primary"
                          className="!w-fit !h-[50px]"
                        />
                      </div>
                    )}
                    <Box sx={{ marginTop: "32px" }}>
                      <Stack direction={"row"} spacing={2}>
                        {AppConstants.API_KEY_PRODUCT_LIST.map((items) => {
                          const tempData = {
                            ...items,
                            planType: planType,
                            expiryData: expiryData,
                            products: items.products.map((product) => ({
                              ...product,
                              status: productStatus[product.name],
                            })),
                          };
                          return (
                            <ApiKeyProductsListCard
                              heading={tempData.heading}
                              subHeading={tempData.subHeading}
                              products={tempData.products}
                              apiKey={ele}
                            />
                          );
                        })}
                      </Stack>
                    </Box>
                  </div>
                }
              />
            );
          })}

          {!isLoading && !ApiKeyList.length && !revokedApiKeyList.length && (
            <NoDataFound text={"There is no APIKEY"} className="mt-52" />
          )}

          <div className="mt-[40px]">
            {revokedApiKeyList.length ? (
              <RevokeAPIKey listData={revokedApiKeyList} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default YourApiKeyNew;
