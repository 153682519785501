import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { UserNameText } from "../CommonComponents";
import { Avatar, Box, Typography } from "@mui/material";
import { ClosePopup, PencilIcon } from "../../../assets/svgs";
import styled from "@emotion/styled";
import APIKeyList from "./APIKeyList/APIKeyList";
import UserDetails from "../../UserDetails/UserDetails";
import LoaderComponent from "../LoaderComponent";
import YourUsage from "../../UserMode/YourUsage/YourUsage";
import Teams from "../../UserDetails/Teams";
import BillingDetailsTab from "../../UserDetails/BillingDetailsAdminMode";
import QueryAnalysis from "../../UserDetails/QueryAnalysis";
import AddMoreUserToTeam from "../../Teams/AddMoreUserToTeam/addMoreUserToTeam";
import InputTextField from "../InputTextField";
import { AppConstants } from "../../../constants/AppConstants";
import TrestleButton from "../TrestleButton";
import {
  getUser,
  updateCompanyName,
} from "../../../http/authentication-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import isEqual from "lodash/isEqual";
import { isEmpty } from "lodash";
import YourUsageV2 from "../../UserMode/YourUsage/YourUsageV2";
import { ActionButtonContainer } from "../PaymentWallet/WalletComponets/Styles";
import DialogPopup from "../DialogPopup";
import {
  ResetUserPassword,
  UpdateUserStatus,
} from "../../../http/admin-service";
import UserVerificationData from "../../UserDetails/UserVerificationData";
import VerificationData from "../../UserDetails/UserVerificationData";

interface ComponentDrawerProps {
  open: boolean;
  anchor: "top" | "right";
  userName?: string;
  close?: any;
  children: any;
  width?: string;
  userData?: any;
  closeOnBackdropClick?: boolean;
}

interface TeamFormData {
  team: string;
  company: string;
  teamId: string;
  companyId: string;
  userId: string;
}

export default function ComponentDrawer({
  open,
  anchor,
  userName = "",
  close = () => {},
  children,
  width = "1130px",
  userData = null,
  closeOnBackdropClick = false,
}: ComponentDrawerProps) {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<string>("");

  const TabOptions = [
    "Subscriptions",
    "User Details",
    "Usage",
    "Team Members",
    "Billing Details",
    "Query Analysis",
    "Verification Data",
  ];

  const initialFormSate = {
    team: "",
    company: "",
    teamId: "",
    companyId: "",
    userId: "",
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addMoreUser, setAddMoreUser] = useState<boolean>(false);
  const [formData, setFormData] = useState<TeamFormData>(initialFormSate);
  const [fullName, setFullName] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isDisableUserOpen, setIsDisableUserOpen] = useState<boolean>(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);

  useEffect(() => {
    setSelectedTab("Subscriptions");
  }, []);

  useEffect(() => {
    const compoent_div = document.getElementById("scroll-comp");
    compoent_div?.scrollTo(0, 0);
  }, [selectedTab]);

  const setInitialFormdate = async () => {
    const response = await getUser(userData.email);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      const {
        id = "",
        company = null,
        name: teamName = "",
      } = data.data?.team || {};
      const { id: companyId = "", name: companyName = "" } = company || {};
      setFullName(
        `${data.data?.firstName || "-"} ${data.data?.lastName || ""}`
      );
      const formData = {
        company: companyName,
        team: teamName,
        companyId: companyId,
        teamId: id,
        userId: userData?.id || "",
      };
      setFormData(formData);
      setCurrentUser(data.data);
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const changeUserStatus = async () => {
    setIsLoading(true);
    const payload = {
      user_id: currentUser?.id,
      new_user_status:
        currentUser?.entityStatus === "ACTIVE" ? "DEACTIVATED" : "ACTIVE",
    };
    const response = await UpdateUserStatus(payload);
    const { status = 401, data: newUserData = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setIsDisableUserOpen(false);
      setInitialFormdate();
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg:
            newUserData?.data?.entityStatus === "ACTIVE"
              ? "User is reactivated successfully"
              : "User is deactivated successfully",
        })
      );
      setIsLoading(false);
    }
  };

  const resetUserPassword = async (email: string) => {
    setIsLoading(true);
    const response = await ResetUserPassword(email);
    setIsLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: "User password reset and email sent",
          autoCloseTime: 5000,
        })
      );
      setOpenResetDialog(false);
    } else {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Error resetting password",
          autoCloseTime: 3000,
        })
      );
      setOpenResetDialog(false);
    }
  };

  return (
    <>
      <LoaderComponent loader={isLoading} />

      <Drawer
        anchor={anchor}
        open={open}
        className="w-fit"
        PaperProps={{
          sx: {
            width: "80%",
            maxWidth: width,
            marginLeft: anchor === "top" ? "auto" : "",
            height: "100%",
            background: "#F5F4F4",
            overflow: "hidden",
          },
        }}
        onClose={() => {
          if (closeOnBackdropClick) {
            close();
          }
        }}
      >
        <div
          id="scroll-comp"
          className={`${
            fullName ? "pt-[0px]" : "pt-[90px]"
          } h-full bg-[#EAEBEC] overflow-y-auto`}
        >
          {fullName && (
            <div className="px-[45px] pt-[45px] bg-[#EAEBEC] sticky top-[90px] z-10">
              <div className="pb-5 flex justify-between items-center">
                <div className="flex flex-1">
                  <Avatar
                    alt={fullName || ""}
                    src="/static/images/avatar/2.jpg"
                    className="!h-8 !w-8"
                  />
                  <UserNameText w={"700px"} className="!h-[30px]">
                    {fullName}
                  </UserNameText>
                </div>
                <div className="flex items-center gap-4">
                  <ActionButtonContainer
                    onClick={() => {
                      setOpenResetDialog(true);
                    }}
                    className="!underline !text-lg"
                  >
                    Reset Password
                  </ActionButtonContainer>
                  {currentUser?.entityStatus === "ACTIVE" ? (
                    <ActionButtonContainer
                      onClick={() => setIsDisableUserOpen(true)}
                      className="!underline !text-lg !text-red-700"
                    >
                      Deactivate User
                    </ActionButtonContainer>
                  ) : (
                    <ActionButtonContainer
                      onClick={() => setIsDisableUserOpen(true)}
                      className="!underline !text-lg !text-green-500"
                    >
                      Reactivate User
                    </ActionButtonContainer>
                  )}
                  <div
                    onClick={() => {
                      close();
                      setSelectedTab("Subscriptions");
                    }}
                    className="cursor-pointer w-fit"
                  >
                    {ClosePopup}
                  </div>
                </div>
              </div>

              <div className="flex flex-1 pb-5">
                <div className="flex">
                  <UserNameText
                    w={"fit-content"}
                    className="!h-[30px] !text-lg !ml-0"
                  >
                    <b className="mr-1">Company:</b>
                    {formData.company || (
                      <span className="opacity-50">Not Available</span>
                    )}
                  </UserNameText>
                  <UserNameText
                    w={"fit-content"}
                    className="!h-[30px] !text-lg !ml-3 !flex"
                  >
                    <b className="mr-1">Team:</b> {formData.team}{" "}
                  </UserNameText>
                </div>
              </div>

              <div className="flex">
                {TabOptions.map((tab: string) => {
                  return (
                    <TabTextComponent
                      selected={selectedTab === tab}
                      key={tab}
                      onClick={() => {
                        setSelectedTab(tab);
                      }}
                    >
                      {tab}
                    </TabTextComponent>
                  );
                })}
              </div>
            </div>
          )}
          {children || (
            <div
              className={`p-10 ${
                [TabOptions[0], TabOptions[3]].includes(selectedTab)
                  ? "bg-[#F5F4F4]"
                  : "bg-white"
              } min-h-[calc(100vh_-_195px)]`}
            >
              {selectedTab === TabOptions[0] && (
                <div className="mt-[80px] pb-[100px]">
                  <APIKeyList
                    userData={userData}
                    usersDetails={currentUser}
                    setInitialFormdate={setInitialFormdate}
                    isAdmin={true}
                  />
                </div>
              )}
              {selectedTab === TabOptions[1] && (
                <div className="mt-[90px]">
                  <UserDetails
                    setLoading={setIsLoading}
                    userData={userData.email}
                    setInitialFormdate={setInitialFormdate}
                    isAdmin={true}
                  />
                </div>
              )}
              {/* {selectedTab === TabOptions[2] && (
                <div className="mt-[90px]">
                  <YourUsage
                    userId={userData?.id || 0}
                    userEmail={userData.email}
                  />
                </div>
              )} */}
              {/* version 2 */}
              {selectedTab === TabOptions[2] && (
                <div className="mt-[80px]">
                  <YourUsageV2
                    usersDetails={currentUser}
                    userId={userData?.id || 0}
                    userEmail={userData.email}
                    isAdmin={true}
                    setInitialFormdate={setInitialFormdate}
                  />
                </div>
              )}
              {selectedTab === TabOptions[3] && (
                <div className="mt-[80px]">
                  <Teams
                    usersDetails={currentUser}
                    teamId={formData?.teamId || null}
                    setLoading={setIsLoading}
                    closeDrawer={close}
                    showFields={false}
                    setAddMoreUser={setAddMoreUser}
                    isAdmin={true}
                    setInitialFormdate={setInitialFormdate}
                  />
                </div>
              )}
              {selectedTab === TabOptions[4] && (
                <div className="mt-[80px]">
                  <BillingDetailsTab
                    setLoading={setIsLoading}
                    setDialogOpen={close}
                    userEmail={userData.email}
                  />
                </div>
              )}
              {selectedTab === TabOptions[5] && (
                <div className="mt-[80px]">
                  <QueryAnalysis
                    setLoading={setIsLoading}
                    userId={userData.id}
                    isAdmin={true}
                  />
                </div>
              )}
              {selectedTab === TabOptions[6] && (
                <div className="mt-[80px]">
                  <VerificationData
                    usersDetails={currentUser}
                    setLoading={setIsLoading}
                    isAdmin={true}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {addMoreUser && (
          <AddMoreUserToTeam
            open={addMoreUser}
            setPopup={setAddMoreUser}
            setIsLoading={setIsLoading}
            teamData={formData}
          />
        )}

        <DialogPopup
          open={isDisableUserOpen}
          onClose={() => setIsDisableUserOpen(false)}
          className="!p-0 w-[700px]"
          component={
            <>
              <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
                {userData?.entityStatus === "ACTIVE" ? (
                  <div className="text-xl font-bold flex">Deactivate User</div>
                ) : (
                  <div className="text-xl font-bold flex">Reactivate User</div>
                )}
              </div>
              {currentUser?.entityStatus === "ACTIVE" ? (
                <Typography className="flex mt-[20px] py-8 px-10">
                  Are you sure you want to deactivate this user? Once you
                  deactivate this user, it will not be able to login to the
                  portal.
                </Typography>
              ) : (
                <Typography className="flex mt-[20px] py-8 px-10">
                  Are you sure you want to reactivate this user? Once you
                  reactivate this user, it will be able to login with it's
                  existing credentials and access the api key and other details.
                </Typography>
              )}

              <Box className="flex gap-[10px] mt-[20px] py-8 px-10">
                {currentUser?.entityStatus === "ACTIVE" ? (
                  <TrestleButton
                    onClick={() => changeUserStatus()}
                    label="Deactivate User"
                    // disabled={disabledSubmit()}
                    type="error"
                    className="!h-[40px]!min-w-[fit-content]"
                  />
                ) : (
                  <TrestleButton
                    onClick={() => changeUserStatus()}
                    label="Reactivate User"
                    // disabled={disabledSubmit()}
                    type="primary"
                    className="!h-[40px]!min-w-[fit-content]"
                  />
                )}
                <TrestleButton
                  onClick={() => {
                    setIsDisableUserOpen(false);
                  }}
                  label="Cancel"
                  // disabled={disabledSubmit()}
                  type="secondry"
                  className="!h-[40px]!min-w-[fit-content]"
                />
              </Box>
            </>
          }
        />
        <DialogPopup
          open={openResetDialog}
          onClose={() => setOpenResetDialog(false)}
          className="!p-0 w-[700px]"
          component={
            <>
              <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
                <div className="text-xl font-bold flex">Reset Password</div>
              </div>
              <Typography className="flex mt-[20px] py-8 px-10">
                Are you sure you want to reset this user's password and send an
                email?
              </Typography>
              <Box className="flex gap-[10px] mt-[20px] py-8 px-10">
                <TrestleButton
                  onClick={() => resetUserPassword(userData.email)}
                  label="Confirm"
                  type="primary"
                  className="!h-[25px]!min-w-[fit-content]"
                />
                <TrestleButton
                  onClick={() => setOpenResetDialog(false)}
                  label="Cancel"
                  type="secondry"
                  className="!h-[25px]!min-w-[fit-content]"
                />
              </Box>
            </>
          }
        />
      </Drawer>
    </>
  );
}

export const TabTextComponent = styled.div<{ selected: boolean }>`
  font-size: 16px;
  font-weight: ${(props: any) => (props.selected ? "500" : "400")};
  border-bottom: ${(props: any) =>
    props.selected ? "4px solid #0494B4" : "none"};
  height: 47px;
  cursor: pointer;
  margin-right: 30px;
  color: ${(props: any) => (props.selected ? "#163545" : "#566D76")};
`;
